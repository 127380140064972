import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { PRODUCTS_MOCK } from "../__mocks/ProductMock";

export class ProductMockService extends BaseMockService {
    constructor() {
        super('products', PRODUCTS_MOCK);
    }
}

export default class ProductService extends BaseService {
    constructor() {
        super('products', {
            name: String,
            company_id: Number,
            sku: String,
            type: String,
            unit_value: Number,
            unit: String,
            price: Number,
            is_active: Boolean,
        });
    }

    async findAll() {
        const result = await super.get('/v1/products');
        return result.data;
    }

    async findOne(query) {
        throw new Error('ProductService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/products/${id}`);
    }

    async create(data) {
        return super.post('/v1/products', data);
    }

    async update(data, companyId) {
        throw new Error('ProductService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/products/${id}`, data);
    }
}