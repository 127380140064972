<template>
  <div class="templates-container">

    <div class="template-edit">
      <h3>Novo template</h3>

      <div class="template-filter-container">
        <div class="filter-area">
          <Filter icon="calendar-filled" label="Tipo" data="Email"/>
        </div>
        <div class="filter-area">
          <Filter icon="calendar-filled" label="Template" data="Alerta"/>
        </div>

      </div>

      <div class="code-area">
        <div class="variables-container">
          <ul>
            <li><code>{userName}</code> Nome do usuário</li>
            <li><code>{productName}</code> Nome do produto que ativou o alerta</li>
            <li><code>{thresholdPercentage}</code> Porcentagem do threashold configurado para o alerta</li>
            <li><code>{alertDate}</code> Data em que o alerta foi disparado</li>
          </ul>
        </div>

        <textarea v-model="templateCode"/>

        <div class="options-container">
          <AppButton label="Salvar" />
          <AppButton label="Limpar" />
          <AppButton @click="voltar()" label="Meus templates" />
        </div>
      </div>

      <!--
      <h4 style="margin-top: 20px;">Pré-Visualização</h4>

      <div class="preview-container">
        <code v-html="templateCode" />
      </div>
      -->

      <!--
      <div class="save-template-section">
        <AppButton label="Salvar" />
      </div>
      -->
    </div>

  </div>
</template>

<script src="./RegisterNotificationTemplate.js" />
<style scoped src="./RegisterNotificationTemplate.scss" lang="scss" />