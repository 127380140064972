import BaseService from './base-service';
import BaseMockService from './base-mock-service';
import MenuService from './MenuService'
import { USERS_MOCK } from "../__mocks/UserMock";

export class UserMockService extends BaseMockService {
    constructor() {
        super('users', USERS_MOCK);
        this._userService = new UserService();
    }

    get loggedUserKey() { return this._userService.loggedUserKey; }
    get accessTokenKey() { return this._userService.accessTokenKey; }
    get accessTokenExpiresAtKey() { return this._userService.accessTokenExpiresAtKey; }
    get loggedUser() { return this._userService.loggedUser; }
    get accessToken() { return this._userService.accessToken; }
    get accessTokenExpiresAt() { return this._userService.accessTokenExpiresAt; }

    isTokenExpired() { return this._userService.isTokenExpired(); }
    logout() { return this._userService.logout(); }
    setLoggedUser(args) { return this._userService.setLoggedUser(args); }
    isLogged() { return this._userService.isLogged(); }
    syncLoggedUser() { return this._userService.syncLoggedUser(); }

    async validateCredentials(_email, _password, _ignoreErrorHandler) {
       return {
           needs_otp_setup: false,
           mfa_enabled: true,
       }
    }

    async forgotPassword(email, ignoreErrorHandler = false) {
        return {};
    }

    async resetPassword(email, password, token, ignoreErrorHandler = false) {
        return {};
    }

    async login(email, password, totp, ignoreErrorHandler = false) {
        return {
            needs_otp_setup: false,
            user: {
                can_register_mfa_device: false,
                id: 1,
                first_name: 'John',
                last_name: 'Doe',
                email: 'john.doe@mail.com',
                treatment: 'Sr.',
                enable_mfa_setup: false,
                profile: {
                    id: 1,
                    permissions: MenuService.getAllMenuIds(),
                    name: 'Admin',
                    company_id: 1,
                    observations: 'Admin',
                    is_admin: true,
                    is_active: true,
                }
            },
            access_token: '',
            refresh_token: '',
            access_token_expires_in: 1000000000,
            refresh_token_expires_in: 1000000000
        }
    }
}

export default class UserService extends BaseService {
    constructor() {
        super('users', {
            first_name: String,
            last_name: String,
            treatment: String,
            profile_id: Number,
            company_id: Number,
            group_id: Number,
            email: String,
            password: String,
            enable_mfa_setup: Boolean,
            totp: String
        });
        this._loggedUser = null;
        this._accessToken = null;
        this._accessTokenExpiresAt = null;
        this.syncLoggedUser();
    }

    get loggedUserKey() {
        return `${this._serviceName}-logged-user`;
    }

    get accessTokenKey() {
        return `${this._serviceName}-access-token`;
    }

    get accessTokenExpiresAtKey() {
        return `${this._serviceName}-access-token-expires-at`;
    }

    get loggedUser() {
        return this._loggedUser;
    }

    get accessToken() {
        return this._accessToken;
    }

    get accessTokenExpiresAt() {
        return this._accessTokenExpiresAt;
    }

    isTokenExpired() {
        return !isNaN(this.accessTokenExpiresAt) && Date.now() > Number(this.accessTokenExpiresAt);
    }

    logout() {
        this._loggedUser = null;
        this._accessToken = null;
        this._accessTokenExpiresAt = null;
        localStorage.removeItem(this.loggedUserKey);
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem(this.accessTokenExpiresAtKey);
    }

    setLoggedUser({ needs_otp_setup, user, access_token, access_token_expires_in }) {
        if (user) {
            if (needs_otp_setup) {
                this._loggedUser = { ...user, needs_otp_setup };
            } else {
                this._loggedUser = user;
            }
            this._accessToken = access_token;
            this._accessTokenExpiresAt = (Date.now() + (access_token_expires_in * 1000)).toString();

            localStorage.setItem(this.loggedUserKey, JSON.stringify(this._loggedUser));
            localStorage.setItem(this.accessTokenKey, this._accessToken);
            localStorage.setItem(this.accessTokenExpiresAtKey, this._accessTokenExpiresAt);
        }
    }

    isLogged() {
        this.syncLoggedUser();
        return this._loggedUser !== null;
    }

    syncLoggedUser() {
        const syncKey = (key, parseJson = true) => {
            const rawData = localStorage.getItem(key);
            if (rawData && rawData !== '') return parseJson ? JSON.parse(rawData) ?? null : rawData;
            else return null;
        };
        this._loggedUser = syncKey(this.loggedUserKey);
        this._accessToken = syncKey(this.accessTokenKey, false);
        this._accessTokenExpiresAt = syncKey(this.accessTokenExpiresAtKey, false);
    }

    async validateCredentials(email, password, ignoreErrorHandler = false) {
        return super.post('/v1/auth/validate', { email, password }, null, null, ignoreErrorHandler);
    }

    async forgotPassword(email, ignoreErrorHandler = false) {
        return super.post('/v1/auth/forgot-password', { email }, null, null, ignoreErrorHandler);
    }

    async resetPassword(email, password, token, ignoreErrorHandler = false) {
        return super.post('/v1/auth/reset-password', { email, password, token }, null, null, ignoreErrorHandler);
    }

    async login(email, password, totp, ignoreErrorHandler = false) {
        const payload = { email, password };
        if (totp != null) {
            payload.totp = totp;
        }
        return super.post('/v1/auth', payload, null, null, ignoreErrorHandler);
    }

    async findAll() {
        const result = await super.get('/v1/users');
        return result.data;
    }

    async findOne(query) {
        throw new Error('UsersService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/users/${id}`);
    }

    async create(data) {
        return super.post('/v1/users', data);
    }

    async update(data, companyId) {
        throw new Error('UsersService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/users/${id}`, data);
    }
}
