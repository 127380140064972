<template>
  <div class="navbar">
    <div class="navbar-content">

      <div class="app-logo">
        <!--
        <img alt="" src="../../../assets/logo-white-bg.png"/>
        -->
        <Logo class="app-logo-content"/>
      </div>

      <div class="search-bar">
        <div class="search-container">
          <div class="icon-container">
            <Icon type="search"/>
          </div>
          <input type="text" placeholder="Buscar..."/>
        </div>
      </div>

      <div style="z-index: 1;" class="user-options">
        <!--
        <div class="user-option">
          <div class="icon-container">
            <Icon type="bell"/>
          </div>
        </div>
        -->

        <div @click="toggleUserOptions()" class="user-option">
          <div class="icon-container">
            <Icon type="user-circle-filled"/>
          </div>
        </div>
      </div>

      <UserOptions @on-option-selected="toggleUserOptions(false)" v-if="showUserOptions" />

    </div>
  </div>
</template>

<script src="./Navbar.js"/>
<style scoped src="./Navbar.scss" lang="scss"/>