<template>
  <div class="mfa-container">
    <div class="mfa-logo">
      <img alt="" src="../../../assets/logo-transparent.png"/>
      <!--
      <Logo class="mfa-logo-content"/>
      -->
    </div>

    <h2 style="margin-top: 20px">Autentique sua conta</h2>
    <h4 style="margin-top: 10px; font-weight: normal;">Informe o código de 6 dígitos do seu aplicativo <br/>autenticador de dois fatores.</h4>

    <div class="token-container">
      <div class="token-input">
        <input v-for="token of tokenData"
               v-model="token.data"
               type="text"
               maxlength="1"
               minlength="1"
               :id="'mfa-' + token.position"
               @keyup.enter="onEnter('mfa')"
               @keyup="onTokenInput($event, token)">
      </div>
      <AppButton @click="sendMFA" label="Confirmar" />
    </div>

    <a class="cancel-action" href="#" @click="cancel">Voltar</a>
  </div>
</template>

<script src="./ConfirmMFA.js" />
<style scoped src="./ConfirmMFA.scss" lang="scss" />