export default {
    name: 'AppInput',
    emits: ['update:modelValue'],
    props: {
        type: String,
        label: String,
        placeholder: String,
        modelValue: {
            type: String,
            required: false
        }
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}