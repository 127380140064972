import { Icon } from '../../components';
import {
    userServiceInstance as userService
} from '../../services';
import Chart from './chart/Chart.vue';
import { generateDoughnutData } from '../../__mocks/ChartDataMock';

export default {
    name: "Home",

    components: {
        Icon,
        Chart
    },

    data: () => ({
        doughnutData_1: generateDoughnutData({ labels: [ 'Critical', 'High', 'Medium', 'Low', 'Minor' ] }),
        doughnutData_2: generateDoughnutData({ labels: [ 'Implemented', 'Pending' ] }),
        doughnutData_3: generateDoughnutData({ labels: [ 'Critical', 'High', 'Medium', 'Low', 'Minor' ] }),
        doughnutData_4: generateDoughnutData({ labels: [ 'Implemented', 'Pending' ] }),

        userInfo: null,

        barOptions: {

        }
    }),

    mounted() {
        this.userInfo = userService.loggedUser;
    }
}