import { AppButton, Slide } from "../../../components";
import {
    productServiceInstance as productService,
    companyServiceInstance as companyService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterProduct",
    components: { AppButton, Slide },
    methods: {
        async saveProduct() {
            let alertDesc = '';
            if (this.isEdit) {
                await productService.updateById(this.product, this.product.id);
                alertDesc = 'Produto atualizada!';
            } else {
                await productService.create(this.product);
                alertDesc = 'Produto criada!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/products/list');
        }
    },
    data() {
        return {
            product: {},
            companies: [],
            isEdit: false
        }
    },
    async mounted() {
        this.companies = await companyService.findAll();
        const productId = this.$route.query.id;
        if (productId) {
            this.product = (await productService.findOneById(productId)) ?? {};
            this.isEdit = true;
        }
    }
}