import { AppButton, Slide } from "../../../components";
import {
    projectServiceInstance as projectService,
    companyServiceInstance as companyService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterProject",
    components: { AppButton, Slide },
    methods: {
        async saveProject() {
            let alertDesc = '';
            if (this.isEdit) {
                await projectService.updateById(this.project, this.project.id);
                alertDesc = 'Projeto atualizada!';
            } else {
                await projectService.create(this.project);
                alertDesc = 'Projeto criada!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/projects/list');
        }
    },
    data() {
        return {
            project: {},
            companies: [],
            isEdit: false
        }
    },
    async mounted() {
        this.companies = await companyService.findAll();
        const projectId = this.$route.query.id;
        if (projectId) {
            this.project = (await projectService.findOneById(projectId)) ?? {};
            this.isEdit = true;
        }
    }
}