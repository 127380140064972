import { AppButton } from "../../../components";
import {
    companyServiceInstance as companyService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterCompany",
    components: { AppButton },
    methods: {
        async saveCompany() {
            let alertDesc = '';
            if (this.isEdit) {
                await companyService.updateById(this.company, this.company.id);
                alertDesc = 'Empresa atualizada!';
            } else {
                await companyService.create(this.company);
                alertDesc = 'Empresa criada!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/companies/list');
        }
    },
    data() {
        return {
            company: {},
            isEdit: false
        }
    },
    async mounted() {
        const companyId = this.$route.query.id;
        if (companyId) {
            this.company = (await companyService.findOneById(companyId)) ?? {};
            this.isEdit = true;
        }
    }
}