<template>
  <div id="register-company">

    <div class="form-input-container">

      <div class="form-input-section">
        <span>Nome:</span>
        <input v-model="company.name" type="text" placeholder="Digite o nome da empresa"/>
      </div>

      <div style="display: inline-block; width: calc(80% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Endereço:</span>
        <input v-model="company.address" type="text" placeholder="Digite o endereço"/>
      </div>

      <div style="display: inline-block; width: calc(20% - 10px); margin-left: 10px;" class="form-input-section">
        <span>CEP:</span>
        <input v-model="company.zipcode" type="text" placeholder="Digite o CEP"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Cidade:</span>
        <input v-model="company.city" type="text" placeholder="Digite a cidade"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Estado:</span>
        <input v-model="company.state" type="text" placeholder="Digite o estado"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Telefone:</span>
        <input v-model="company.cellphone" type="tel" placeholder="Digite o telefone"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Email Principal:</span>
        <input v-model="company.email" type="email" placeholder="Digite o email principal"/>
      </div>

      <div style="display: inline-block; width: calc(33% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Email Financeiro:</span>
        <input v-model="company.financial_email" type="text" placeholder="Digite o email financeiro"/>
      </div>

      <div style="display: inline-block; width: calc(33% - 20px); margin: 0 10px;" class="form-input-section">
        <span>Email Técnico:</span>
        <input v-model="company.technical_email" type="text" placeholder="Digite o email técnico"/>
      </div>

      <div style="display: inline-block; width: calc(33% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Email Comercial:</span>
        <input v-model="company.commercial_email" type="text" placeholder="Digite o email comercial"/>
      </div>

      <div style="display: inline-block; width: calc(30% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Logo:</span>
        <input v-model="company.logo_url" type="text" placeholder="Digite a URL da Logo"/>
        <!--
        <input id="emp_img_logo" type="file" placeholder="Selecione o arquivo"/>
        -->
      </div>

      <div style="display: inline-block; width: calc(70% - 10px); margin-left: 10px;" class="form-input-section">
        <span>URL:</span>
        <input v-model="company.company_url" type="text" placeholder="Digite a URL"/>
      </div>

      <div class="form-input-section">
        <span>Observações:</span>
        <!--
        <input type="text" placeholder="Digite as observações"/>
        -->
        <textarea v-model="company.observations" placeholder="Digite as observações"/>
      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveCompany()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterCompany.js"/>
<style scoped src="./RegisterCompany.scss" lang="scss" />