import { Icon, Filter, AppButton } from "../../../components";
import CLOUD_REPORTS from '../../../__mocks/CloudReportMock';

const CLOUD_REPORTS_COLUMNS = [
    { active: true, key: 'CREATED_AT', label: 'Data Criação', width: '120px', pullRight: false },
    { active: true, key: 'PRODUCT', label: 'Serviço/Produto', width: '120px', pullRight: false },
    { active: true, key: 'ENVIRONMENT', label: 'Ambiente', width: '120px', pullRight: false },
    { active: true, key: 'DISK_PERFORMANCE', label: 'Performance Disco', width: '120px', pullRight: false },
    { active: true, key: 'GB', label: 'Gb', width: '120px', pullRight: false },
    { active: true, key: 'HOURS_QUANTITY', label: 'Qtd. Horas', width: '120px', pullRight: false },
    { active: true, key: 'QUANTITY', label: 'Qtd.', width: '120px', pullRight: false },
    { active: true, key: 'UPFRONT', label: 'Upfront', width: '120px', pullRight: false },
    { active: true, key: 'ON_DEMAND', label: 'On-demand', width: '120px', pullRight: false },
    { active: true, key: 'USN_QUANTITY', label: 'Qtd. USN', width: '120px', pullRight: false },
    { active: true, key: 'TOTAL', label: 'Total em R$', width: '120px', pullRight: true },
    { active: true, key: 'USN_VALUE', label: 'Valor USN', width: '120px', pullRight: true },
];

export default {
    name: "CloudReport",

    components: {
        Icon,
        Filter,
        AppButton
    },

    methods: {
        isColumnActive(columnKey) {
            return this.cloudReportsColumns.get(columnKey).active;
        },

        getColumns() {
            return Array.from(this.cloudReportsColumns.values());
        },

        getActiveColumnsLength() {
            return Array.from(this.cloudReportsColumns.values())
                .filter(column => column.active)
                .length;
        },

        getColumnsLength() {
            return Array.from(this.cloudReportsColumns.values())
                .length;
        },

        toggleColumn(columnKey) {
            const column = this.cloudReportsColumns.get(columnKey);
            if (column) {
                column.active = !column.active;
            }
        },

        expandRow(row) {
            console.log(row)
            row._expanded = !row._expanded;
        },

        toggleColumnOptions() {
            this.showColumnOptions = !this.showColumnOptions;
        },

        toggleMoreFilters() {
            this.showMoreFilters = !this.showMoreFilters;
        }
    },

    data() {
        return {
            cloudReportsColumns: new Map(CLOUD_REPORTS_COLUMNS.map(column => [column.key, column])),
            cloudReports: CLOUD_REPORTS,
            showColumnOptions: false,
            showMoreFilters: false
        }
    }
}