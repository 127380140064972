<template>
  <div class="filter-option">
    <!--
    <div class="filter-icon">
      <Icon :type="icon"/>
    </div>
    -->
    <span class="filter-label">{{label}}:</span>
    <!--
    <span class="filter-data">{{data}}</span>
    -->

    <select class="filter-select shadow-1">
      <option :value="data" selected>{{data}}</option>
    </select>
    <div class="filter-select-icon">
      <Icon type="chevron-down"/>
    </div>
  </div>
</template>

<script src="./Filter.js" />
<style scoped src="./Filter.scss" lang="scss" />