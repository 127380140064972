import { Icon, Logo } from "../../index";
import UserOptions from "./user-options/UserOptions.vue";

export default {
    name: "Navbar",
    components: {
        Icon,
        Logo,
        UserOptions,
    },
    data: () => ({
        showUserOptions: false
    }),
    methods: {
        toggleUserOptions(value = !this.showUserOptions) {
            this.showUserOptions = value;
        }
    }
}