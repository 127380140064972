import BaseService from "./base-service";
import BaseMockService from "./base-mock-service";

export class SettingsMockService extends BaseMockService {
    constructor() {
        super('settings', []);
    }

    async getMFASecret(_token) {
        return {};
    }

    async setupMFASecret(_payload, _token) {
        return {};
    }
}

export default class SettingsService extends BaseService {
    constructor() {
        super('settings');
    }

    async getMFASecret(token) {
        return await super.get('/v1/auth/mfa-secret', token);
    }

    async setupMFASecret(payload, token) {
        return await super.post('/v1/auth/setup-mfa', payload, token);
    }
}