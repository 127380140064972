import { faker } from '@faker-js/faker';

export const GROUPS_MOCK = [
    {
        id: 1,
        name: faker.company.name(),
        company_id: 1,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        name: faker.company.name(),
        company_id: 1,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]