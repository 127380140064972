<template>
  <div class="security-container">
    <div class="setup-mfa shadow-1">

      <div v-if="!userInfo?.can_register_mfa_device" class="mfa-register-disabled">
        <div class="mfa-disabled-message shadow-1">
          <span>
            {{
              userInfo?.enable_mfa_setup
                  ? "Dispositivo de autenticação já registrado."
                  : "Cadastro de dispositivo de autenticação não disponível."
            }}
          </span>
        </div>
      </div>

      <h3>Verificação de autenticação</h3>

      <h5 style="font-weight: normal; margin-top: 10px; margin-bottom: 20px;">
        Escaneie a imagem abaixo com o <b>aplicativo autenticador de dois fatores</b> em seu telefone.
      </h5>

      <span v-if="mfaData?.qrCode == null">
        Carregando...
      </span>
      <img v-if="mfaData?.qrCode" :src="'data:image/png;base64, ' + mfaData?.qrCode"/>

      <h5 style="font-weight: normal; margin-top: 20px">
        Se você não conseguir escanear o QR Code, digite <code>{{mfaData?.secret ?? 'Carregando...'}}</code> no <b>aplicativo autenticador</b>.
      </h5>

      <h4 style="margin-top: 30px;">Informe o código de 6 dígitos do seu aplicativo autenticador de dois fatores.</h4>

      <input :disabled="!userInfo?.can_register_mfa_device" v-model="initialCode"  type="text" maxlength="6" minlength="6" placeholder="###-###"/>

      <h4 style="margin-top: 30px;">Informe o identificador para esse dispositivo.</h4>

      <input :disabled="!userInfo?.can_register_mfa_device" v-model="deviceName" style="width: 100px !important;" type="text" />

      <div class="save-button-section">
        <AppButton @click="setupMFA()" label="Confirmar" />
      </div>
    </div>

    <!--
    <div style="margin-top: 20px;" class="setup-mfa shadow-1">
      <h3>Salve seus códigos de recuperação.</h3>

      <pre>23423-s9344     23423-s9344
23423-s9344     23423-s9344
23423-s9344     23423-s9344
23423-s9344     23423-s9344
23423-s9344     23423-s9344
23423-s9344     23423-s9344</pre>

      <h5 style="margin-top: 30px">
        Por que salvar seus códigos de recuperação é importante?
      </h5>

      <h5 style="font-weight: normal; margin-top: 10px">
        Se você perder o acesso ao seu telefone, poderá se autenticar usando seus códigos de recuperação.
        <br/>Recomendamos salvá-los em um local seguro.
      </h5>
    </div>
    -->
  </div>
</template>

<script src="./SecuritySettings.js" />
<style scoped src="./SecuritySettings.scss" lang="scss" />