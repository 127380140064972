import { AppButton, Slide } from "../../../components";
import {
    userServiceInstance as userService,
    companyServiceInstance as companyService,
    groupsServiceInstance as groupsService,
    profileServiceInstance as profileService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterUser",
    components: { AppButton, Slide },
    methods: {
        async saveUser() {
            if (this.validate()) {
                let alertDesc = '';
                if (this.isEdit) {
                    await userService.updateById(this.user, this.user.id);
                    alertDesc = 'Usuário atualizado!';
                } else {
                    await userService.create(this.user);
                    alertDesc = 'Usuário criado!';
                }
                alertService.success({ desc: alertDesc });
                await this.$router.push('/users/list');
            }
        },

        validate() {
            if (this.user.password || this.user.password_confirmation) {
                if (this.user.password == null) {
                    alertService.warning({ desc: 'Informe a senha!' });
                }
                if (this.user.password_confirmation == null) {
                    alertService.warning({ desc: 'Confirme a senha!' });
                }
                if (this.user.password !== this.user.password_confirmation) {
                    alertService.warning({ desc: 'Senhas não conferem, verifique e tente novamente!' });
                }
            }

            return true;
        }
    },
    data() {
        return {
            user: {
                enable_mfa_setup: false,
                is_active: true
            },
            companies: [],
            groups: [],
            profiles: [],
            isEdit: false
        }
    },
    async mounted() {
        this.companies = await companyService.findAll();
        this.groups = await groupsService.findAll();
        this.profiles = await profileService.findAll();
        const userId = this.$route.query.id;
        if (userId) {
            this.user = (await userService.findOneById(userId)) ?? {};
            this.isEdit = true;
        }
    }
}