<template>
  <div class="login-container logo-bg">
    <div :class="getLeftPanelClass()">

      <div class="input-container">
        <div class="login-logo">
          <Logo class="login-logo-content"/>
        </div>
        <h1>O sistema não pode ser acessado através de um dispositivo mobile.</h1>
    </div>
    <div :class="getRightPanelClass()">

    </div>
  </div>
  </div>
</template>

<script src="./MobileBlock.js" />
<style scoped src="../login/Login.scss" lang="scss" />
