import { AppInput, AppButton, Logo } from "../../../components";
import {
    alertServiceInstance as alertService
} from "../../../services";

function getTokenInputMap(tokenLength) {
    return new Array(tokenLength)
        .fill("")
        .map((data, index) => ({ data, position: index }));
}

export default {
    name: "ConfirmMFA",
    components: {
        AppInput,
        AppButton,
        Logo,
    },
    emits: ['on-confirm', 'on-cancel'],
    data: () => ({
        tokenData: getTokenInputMap(6),
        processingTokenInput: false
    }),
    mounted() {
        this.focusTokenInput(0);
    },
    methods: {
        cancel() {
            this.$emit('on-cancel', true);
        },

        sendMFA() {
            const tokenData = this.getTokenData();
            if (tokenData.length > 5)   this.$emit('on-confirm', tokenData);
            else                        alertService.error({ title: 'Verifique os campos', desc: 'Digite o código MFA.'});
        },

        getTokenData() {
            return this.tokenData.reduce((acc, { data }) => acc + data, "")
        },

        focusTokenInput(position) {
            document.getElementById('mfa-' + position)?.focus()
        },

        onTokenInput(event, token) {
            if (event.key !== token.data && event.key !== 'Backspace') {
                return;
            }

            const regex = new RegExp("^[a-zA-Z0-9 ]+$");
            const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);

            if (regex.test(str)) {
                this.focusTokenInput(token.position + 1);
            } else {
                if (event.key === 'Backspace') {
                    token.data = "";
                    this.focusTokenInput(token.position - 1);
                } else {
                    token.data = "";
                }
                event.preventDefault();
            }
        },

        async onEnter(source) {
            switch (source) {
                case 'mfa':
                    await this.sendMFA();
                    return;
            }
        }
    }
}