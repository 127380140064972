import axios from 'axios';
import { alertServiceInstance as alertService } from './index';

export default class BaseService {
    constructor(serviceName, modelSchema) {
        this._modelSchema = modelSchema;
        this._serviceName = serviceName;
        this._httpClient = axios.create({
            baseURL: process.env.VUE_APP_API_URL
        });
    }

    sanitizeObject(source) {
        const result = {};
        if (this._modelSchema) {
            Object
                .keys(this._modelSchema)
                .forEach(key => Object.assign(result, { [key]: source[key] }))
            return result;
        } else {
            return source;
        }
    }

    async sleep(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    async handleError(err) {
        if (err?.response) {
            if (err?.response?.data?.errors?.length) {
                for (let e of err.response.data.errors) {
                    await this.sleep(10);
                    alertService.error({ title: 'Erro', desc: e.message });
                }
                return;
            } else if (err?.response?.data?.error?.message) {
                alertService.error({
                    title: 'Erro', desc: err.response.data.error.message
                });
                return;
            }
        }
        alertService.error({ title: 'Erro', desc: err.message });
    }

    async post(url, payload, token = null, options = {}, sanitizeObject = true, ignoreErrorHandler = false) {
        try {
            const payloadSanitized = sanitizeObject
                ? this.sanitizeObject(payload)
                : payload;
            const { data } = await this._httpClient.post(url, payloadSanitized, {
                ...this._getDefaultHeaders(token),
                ...options
            });
            return data;
        } catch (err) {
            if (!ignoreErrorHandler) {
                await this.handleError(err);
            }
            throw err;
        }
    }

    async putFormData(url, formData, token = null, options = {}) {
        try {
            const { data } = await this._httpClient.put(url, formData, {
                    ...this._getDefaultHeaders(token, {
                        'Content-Type': 'multipart/form-data'
                    }),
                    ...options
                }
            )

            return data;
        } catch (err) {
            await this.handleError(err);
            throw err;
        }
    }

    async postFormData(url, formData, token = null, options = {}) {
        try {
            const { data } = await this._httpClient.post(url, formData, {
                    ...this._getDefaultHeaders(token, {
                        'Content-Type': 'multipart/form-data'
                    }),
                    ...options
                }
            )

            return data;
        } catch (err) {
            await this.handleError(err);
            throw err;
        }
    }

    async put(url, payload, token = null, options = {}, sanitizeObject = true) {
        try {
            const payloadSanitized = sanitizeObject
                ? this.sanitizeObject(payload)
                : payload;
            const { data } = await this._httpClient.put(url, payloadSanitized, {
                ...this._getDefaultHeaders(token),
                ...options
            });
            return data;
        } catch (err) {
            await this.handleError(err);
            throw err;
        }
    }

    async download(url, token = null, params = {}, options = {}) {
        try {
            return await this._httpClient.get(url, {
                params: params,
                ...this._getDefaultHeaders(token),
                ...options
            });
        } catch (err) {
            await this.handleError(err);
            throw err;
        }
    }

    async get(url, token = null, params = {}, options = {}) {
        try {
            const { data } = await this._httpClient.get(url, {
                params: {
                    ...params,
                    limit: process.env.VUE_APP_DEFAULT_PAGINATION_LIMIT,
                    page: 0
                },
                ...this._getDefaultHeaders(token),
                ...options
            });
            return data;
        } catch (err) {
            await this.handleError(err);
            throw err;
        }
    }

    _getDefaultHeaders(token, extraHeaders = {}) {
        const headers = {
            ...extraHeaders
        };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        return {
            headers
        };
    }
}