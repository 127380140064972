<template>
  <div id="on-premise-report">

    <!-- FILTERS -->

    <div class="table-filter-container">
      <div class="table-filters">

        <table class="filter-table-section">
          <tr>
            <!--
            <td><Filter icon="calendar-filled" label="Empresa" data="All"/></td>
            -->
            <td><Filter icon="calendar-filled" label="Período Inicial" data="Jan 2022"/></td>
            <td><Filter icon="calendar-filled" label="Período Final" data="Fev 2022"/></td>
            <td><Filter icon="calendar-filled" label="Projeto" data="All"/></td>
            <!--
            <td><Filter icon="calendar-filled" label="Ambiente" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Grupo" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Produto" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Dt. Referência (USN)" data="[01/06/2021] R$ 3.26"/></td>
            -->

            <td>
              <div class="link-container action-filters">
                <a @click="toggleMoreFilters">{{showMoreFilters ? 'Esconder' : 'Mais'}} filtros</a>
              </div>
            </td>
          </tr>

          <tr>
            <template v-if="showMoreFilters">
              <td colspan="2"><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
            </template>

            <td :colspan="showMoreFilters ? 3 : 8">
              <div :class="{ 'filter-actions': true, 'filter-actions-compressed': !showMoreFilters }">

                <div class="filter-option-container">
                  <div @click="toggleColumnOptions()"
                       v-bind:class="{
                          'filter-option': true,
                          'select-shadow': true,
                          'filter-option-active': showColumnOptions
                       }">
                    <div class="filter-icon">
                      <Icon type="table-filled"/>
                    </div>
                    <span class="filter-label">Colunas:</span>
                    <span class="filter-data">{{getActiveColumnsLength()}}/{{getColumnsLength()}}</span>
                  </div>

                  <div v-if="showColumnOptions" class="filter-expanded shadow-4">
                    <div class="search-bar">
                      <div class="search-container">
                        <div class="icon-container">
                          <Icon type="search"/>
                        </div>
                        <input type="text" placeholder="Buscar..."/>
                      </div>
                    </div>

                    <div class="list-container">
                      <div v-for="column in getColumns()"
                           @click="toggleColumn(column.key)"
                           class="list-item">
                        <input v-bind:id="column.key" type="checkbox" v-bind:checked="column.active"/>
                        <span>{{column.label}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!--
                <AppButton label="Gerar Relatório" />
                -->
                <AppButton class="app-button-light" label="Limpar Filtros" />

                <div class="link-container">
                  <!--
                  <a @click="toggleMoreFilters">{{showMoreFilters ? 'Esconder' : 'Mais'}} filtros</a>
                  -->
                  <a>Favoritar seleção</a>
                </div>
              </div>
            </td>
          </tr>
        </table>

      </div>
    </div>

    <div style="display: block; text-align: center; padding: 20px 20px 0 20px; font-weight: bold;">
      Ambiente On-Premise
    </div>

    <hr style="margin-top: 14px" class="billing-horizontal-rule"/>

    <!-- END FILTERS -->

    <div class="table-content">
      <!--
      <div class="table-head-container">
        <div class="thc-row">
          <template v-for="data in getColumns()">
            <div v-if="data.active"
                 v-bind:class="{ 'thc-cell': true, 'table-cell-right-text': data.pullRight  }"
                 v-bind:style="{ 'max-width': data.width || '0', 'min-width': data.width, 'width': data.width }">
              {{data.label}}
            </div>
          </template>
        </div>
      </div>
      -->

      <div class="billing-table-container">
        <div class="billing-table">

          <div class="billing-table-row billing-table-head">
            <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;">
              <div class="select-box"></div>
            </div>
            <template v-for="data in getColumns()">
              <div v-if="data.active"
                   v-bind:class="{ 'billing-table-cell': true, 'billing-table-cell-right-text': data.pullRight }"
                   v-bind:style="{ 'max-width': data.width || '0', 'min-width': data.width, 'width': data.width }">
                {{data.label}}
              </div>
            </template>
          </div>


          <div v-for="innerData in onPremiseReports.data" class="billing-table-row">
            <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;">
              <div class="select-box"></div>
            </div>
            <div v-if="isColumnActive('CREATED_AT')" class="billing-table-cell">{{innerData?.dt_criacao}}</div>
            <div v-if="isColumnActive('HOSTNAME')" style="width: 30px" class="billing-table-cell">{{innerData?.hostname}}</div>
            <div v-if="isColumnActive('OPERATION_SYSTEM')" class="billing-table-cell" style="vertical-align: middle">
              {{innerData?.operation_system}}
              <div v-if="innerData?.detail != null" :class="{ 'os-detail': true, 'os-detail-inc': innerData?.detail === 'INC', 'os-detail-dec': innerData?.detail === 'DEC' }">
                {{innerData?.detail}}

                <div class="details-content shadow-2">
                  <table>
                    <tr>
                      <th></th>
                      <th style="text-align: center" colspan="2">Status</th>
                    </tr>
                    <tr>
                      <th style="border-bottom: 1px solid #ccc; padding: 6px 4px; text-align: center;">Data</th>
                      <th style="border-bottom: 1px solid #ccc; padding: 6px 4px; text-align: center;">Anterior</th>
                      <th style="border-bottom: 1px solid #ccc; padding: 6px 4px; text-align: center;">Atual</th>
                    </tr>
                    <tr>
                      <td style="padding: 6px 4px">2021-11-01T15:55:35.000Z</td>
                      <td style="padding: 6px 4px">4 VCPU</td>
                      <td style="padding: 6px 4px">8 VCPU</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="isColumnActive('MEMORY')" class="billing-table-cell">{{innerData?.mem}} GB</div>
            <div v-if="isColumnActive('VCPU')" class="billing-table-cell">{{innerData?.vcpu}} VCPU</div>
            <div v-if="isColumnActive('DISK')" class="billing-table-cell">{{innerData?.armazenamento}} GB</div>
            <div v-if="isColumnActive('IP_ADDRESS')" class="billing-table-cell">{{innerData?.ip_address}}</div>
            <div v-if="isColumnActive('MEMORY_PRICE')" class="billing-table-cell">R$ {{innerData?.qtd_usn}}</div>
            <div v-if="isColumnActive('CPU_PRICE')" class="billing-table-cell">R$ {{innerData?.preco_ativo}}</div>
            <div v-if="isColumnActive('DISK_PRICE')" class="billing-table-cell">R$ {{innerData?.preco_final}}</div>
            <div v-if="isColumnActive('STATUS')" class="billing-table-cell">
              <div :class="{ 'status-online': innerData?.status === 'ON', 'status-offline': innerData?.status === 'OFF' }">
                {{innerData?.status}}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="export-options">
      <AppButton label="Gerar termo" />
    </div>

  </div>
</template>

<script src="./OnPremiseReport.js" />
<style scoped src="./OnPremiseReport.scss" lang="scss"/>