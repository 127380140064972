import { AppButton } from '../../../components';
import {
    alertServiceInstance as alertService,
    userServiceInstance as userService,
    settingsServiceInstance as settingsService
} from "../../../services";

export default {
    name: "SecuritySettings",
    components: {
        AppButton
    },
    methods: {
        async setupMFA() {
            if (this.validateInput()) {
                try {
                    await settingsService.setupMFASecret({
                        deviceName: this.deviceName,
                        initialCode: this.initialCode,
                        secret: this.mfaData.secret
                    }, userService.accessToken);
                    alertService.info({ title: 'Sucesso', desc: 'Autenticador cadastrado com sucesso, realize o login novamente.'});
                    this.logout();
                } catch (err) {
                    console.error(err.message);
                    alertService.error(this.parseErrorMessage(err?.response?.data));
                }
            }
        },

        parseErrorMessage(error) {
            switch (error?.errors[0]?.message) {
                case 'INVALID_CREDENTIALS':
                    return { title: 'Verifique os campos', desc: 'Código inválido.'};
                default:
                    return { tile: 'Tenta novamente.', desc: 'Falha ao comunicar com servidor' };
            }
        },

        logout() {
            userService.logout();
            this.$emit('on-option-selected', this.isMenuFixed);
            this.$router.push({ path: '/', replace: true });
        },

        validateInput() {
            const title = 'Verifique os campos';

            if (this.initialCode == null || this.initialCode === '') {
                alertService.error({ title, desc: 'Informe o código de 6 dígitos.'});
                return false;
            }

            if (this.deviceName == null || this.deviceName === '') {
                alertService.error({ title, desc: 'Informe o identificador do dispositivo.'});
                return false;
            }

            return true;
        }
    },
    data() {
        return {
            mfaData: null,
            deviceName: "my-device",
            initialCode: "",
            userInfo: null
        };
    },
    async mounted() {
        this.userInfo = userService.loggedUser;
        if (userService.isLogged() && this.userInfo?.can_register_mfa_device) {
            this.mfaData = await settingsService.getMFASecret(userService.accessToken);
        }
    },
}