import { AppButton, Slide } from "../../../components";
import {
    usageAlertServiceInstance as usageAlertService,
    productServiceInstance as productService,
    projectServiceInstance as projectService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterUsageAlert",
    components: { AppButton, Slide },
    methods: {
        async saveUsageAlert() {
            let alertDesc = '';
            if (this.isEdit) {
                await usageAlertService.updateById(this.usageAlert, this.usageAlert.id);
                alertDesc = 'Alerta de Uso atualizado!';
            } else {
                await usageAlertService.create(this.usageAlert);
                alertDesc = 'Alerta de Uso criado!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/usage-alerts/list');
        }
    },
    data() {
        return {
            usageAlert: {},
            projects: [],
            products: [],
            isEdit: false
        }
    },
    async mounted() {
        this.projects = await projectService.findAll();
        this.products = await productService.findAll();
        const usageAlertId = this.$route.query.id;
        if (usageAlertId) {
            this.usageAlert = (await usageAlertService.findOneById(usageAlertId)) ?? {};
            this.isEdit = true;
        }
    }
}