const BILLING_REPORTS = {
    "groups": [
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-10-05T15:35:09.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljr2oirni2n47nd5kmusox54y7rcf5stefwrt6mqxyh74vag63qzl3q",
                    "name_product_resource_id": "ATVAPPKHOMP_BV",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 120,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 29.22,
                    "preco_ativo": 0.4427,
                    "preco_final": 95.4584
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-06T04:01:00.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljr7ep2bc7fn77ja75fec2lm7dww7fvjut4pfpkkq52zz3yhvgzk7gq",
                    "name_product_resource_id": "7c557422-8e6b-484c-ae04-ad53838051e8",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "lnx",
            "resource_type": "Block Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": null,
            "on_demand": 0.1355,
            "usn": "3.2668866",
            "armazenamento_total": 170,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 30,
            "qtd_horas_dispositivos_total": 832,
            "qtd_usn_total": 38.7,
            "preco_ativo_total": 0.89,
            "preco_final_total": 126.41,
            "qtd_dispositivos": 2
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-30T18:16:23.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljrmcxhi5wak4eir7epjprnz4zvjtou4vkcv54kzxf64jokthtyv4qa",
                    "name_product_resource_id": "04fc7385-3e56-4ee6-b972-aa191e233b95",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "lnx",
            "resource_type": "Block Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": 0.1355,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 50,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 10,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 9.47,
            "preco_ativo_total": 0.44,
            "preco_final_total": 30.95,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-09-21T19:57:14.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljrsxmngztmbjf6vi5poezzkt57y7mhhsntcvsvqepeg4oe3attu5oq",
                    "name_product_resource_id": "DIVPRDDBWIN_BV2-v2",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 3072,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 748.032,
                    "preco_ativo": 0.4427,
                    "preco_final": 2443.7357
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-09-21T19:57:14.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljrgepgtm6d77dx6da435zrdu4hfb7lnquokbf5ofouw3pbnvdg6qnq",
                    "name_product_resource_id": "DIVPRDDBWIN_BV1-V2",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 8192,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 1994.752,
                    "preco_ativo": 0.4427,
                    "preco_final": 6516.6286
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-08T12:59:45.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljruxc6lzikgzwjb43t232rvsxjzx2tbigukgoh3sa4rffm2ugwycba",
                    "name_product_resource_id": "SAADESDBWIN_BV",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-20T16:01:38.000Z",
                    "product_resource_id": "ocid1.volume.oc1.sa-saopaulo-1.abtxeljrqc5rwidmrgz3qmo2lmnmujnr4fgwgkxyqlqfemf6qfjtktyt4nha",
                    "name_product_resource_id": "SAAPRDDBWIN_BV",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Block Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": 0.1355,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 11776,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 80,
            "qtd_horas_dispositivos_total": 1664,
            "qtd_usn_total": 2867.46,
            "preco_ativo_total": 1.77,
            "preco_final_total": 9367.65,
            "qtd_dispositivos": 4
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-01T15:55:35.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrbql7hh3az24ybefw2wmowrf6tizim35arpmra57qbpjkjda4gxra",
                    "name_product_resource_id": "ATVAPPKHOMP-V7-PUB (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 120,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 22.74,
                    "preco_ativo": 0.4427,
                    "preco_final": 74.289
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-05T18:38:11.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljryy6eg3zqj5bx7lf72heiqvpam2oqv2yzzu7bgqhxeoqmvcztmkga",
                    "name_product_resource_id": "ATVAPPKHOMP-V8-PUB (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 160,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 30.32,
                    "preco_ativo": 0.4427,
                    "preco_final": 99.052
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-05T19:47:30.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrq2a7cydathxiuluxrjn7pcpai56656m7bedqh7vxpykewrcmrppa",
                    "name_product_resource_id": "ATVAPPKHOMP-V9 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 198,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 37.521,
                    "preco_ativo": 0.4427,
                    "preco_final": 122.5769
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-05T21:05:46.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrnkxpt5sm6vfvoim4fwgmepytq277fudijvjxjtfuy5iqapoeokxq",
                    "name_product_resource_id": "oke-csrp4hm7qwq-nm5u775r4ba-sc34p3ebioq-0 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-05T21:05:46.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrrjgwavgl7mw2trhiaod6kud3v7i44cgu5lza6agjc4s6uuodxica",
                    "name_product_resource_id": "oke-csrp4hm7qwq-nm5u775r4ba-sc34p3ebioq-1 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-11T14:17:38.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljroe4ssnhiovimnbcjj72njrbtmdevqq2yse4eqxgpgiydnbrmvs4a",
                    "name_product_resource_id": "oke-csfgl5gkdca-nvukdixjlpa-scme6v46q5a-0 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrk6mbkydm5uputjjtmsnczrmkvc5gz74prcqnwsmbet6jmdjkh7pq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-2 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrtnmfsrdg6pwfqgsmswniu5i2hkwzrbrez4ldt35fwhtz6cczxonq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-0 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrxok3pcbua7ifdojy7llwbpnxxdga6prjk3ry5f37oso2zswgfvza",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-1 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-11-27T22:50:29.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrenden5frylqxtyxmy7qflguzbqcaptx53kyuoaielacof2va56rq",
                    "name_product_resource_id": "SAAIBASTLNX (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-12-06T11:54:34.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrcmqz4ir6e2twcil2nx2dixfzn6bqbxhkuyxvrzknonqfh6o45wnq",
                    "name_product_resource_id": "ATVESMASTERNODE (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-12-23T09:13:29.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrhin32ognmiaazm5eygsfkphzvegj4xuwnqx3m3gqyk4zld774sdq",
                    "name_product_resource_id": "oke-cqirqfti5lq-npcfiaqciuq-sxxpwfwp74a-0 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "lnx",
            "resource_type": "Boot Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": null,
            "on_demand": 0.1355,
            "usn": "3.2668866",
            "armazenamento_total": 928,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 120,
            "qtd_horas_dispositivos_total": 4992,
            "qtd_usn_total": 175.86,
            "preco_ativo_total": 5.31,
            "preco_final_total": 574.5,
            "qtd_dispositivos": 12
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-11-26T17:17:17.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljr6ucnmdsdzfsimrbbq35rcv5z35hvlu4oc7dqoufa7rnfsvyfw4ra",
                    "name_product_resource_id": "SAAIBASTWIN (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 48.512,
                    "preco_ativo": 0.4427,
                    "preco_final": 158.4832
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "win",
            "resource_type": "Boot Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": null,
            "on_demand": 0.1355,
            "usn": "3.2668866",
            "armazenamento_total": 256,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 10,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 48.51,
            "preco_ativo_total": 0.44,
            "preco_final_total": 158.48,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrgyltambaiexs3i4lr5kytqq647ua6sqnocx7ld7g6idxvch2jkya",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-2 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrkyadgn7qqlc7mx3fvo6m6kt5y7n2xka4htvortwgcules6salyaq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-0 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrrt4mpx7ic6zf667bowv6bfmcwkccmix6kewtyxmlogqune5lfxzq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-1 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 50,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 9.475,
                    "preco_ativo": 0.4427,
                    "preco_final": 30.9538
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "lnx",
            "resource_type": "Boot Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": 0.1355,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 150,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 30,
            "qtd_horas_dispositivos_total": 1248,
            "qtd_usn_total": 28.42,
            "preco_ativo_total": 1.33,
            "preco_final_total": 92.86,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-09-17T18:04:51.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrq26dyxw5xgaebm6impvo6yg2z62uw75ga4cvdnzeihdysforrtxq",
                    "name_product_resource_id": "DIVPRDDBWIN-V2 (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-10-21T12:19:51.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrpwenghq56n53lbsrpbxbvfjq4tkq622d2b6rbc7e7thgerk7ssdq",
                    "name_product_resource_id": "SAAHMLDBWIN (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-20T15:33:55.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrqjkjiq4ijvlayguaaitsak2khg5r3k2pczhwlb3tsx6vewkyjc5a",
                    "name_product_resource_id": "SAAPRDDBWIN (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-11-26T22:40:15.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrmhbbj2gcw6yswcfyjgnovzpx5kienz2wddysdbmt3gcgs42lqjaq",
                    "name_product_resource_id": "SAADESDBWIN (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 20,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 62.336,
                    "preco_ativo": 0.4427,
                    "preco_final": 203.6446
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Boot Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": 0.1355,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 1024,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 80,
            "qtd_horas_dispositivos_total": 1664,
            "qtd_usn_total": 249.34,
            "preco_ativo_total": 1.77,
            "preco_final_total": 814.58,
            "qtd_dispositivos": 4
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-10-21T11:41:10.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljr72k4d4uffxwjj6wxtl3f5stbiwyspyounqhjrmfa2om74qqzxv5a",
                    "name_product_resource_id": "SAAHMLAPPAD (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 157,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 48.512,
                    "preco_ativo": 0.4427,
                    "preco_final": 158.4832
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-09-01T15:35:47.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljryqxfnvmwlrpxpxsegbjtwz46rudwuxaae6xuptaoevm5qiqrh4nq",
                    "name_product_resource_id": "ATVDCUPRDAD (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 48.512,
                    "preco_ativo": 0.4427,
                    "preco_final": 158.4832
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-11-26T16:11:46.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrb4jnsrqztbbdponb3nvxaeznqmj4izbr2rw3y5j3nalutiow2b3q",
                    "name_product_resource_id": "SAAPRDAPPAD (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 48.512,
                    "preco_ativo": 0.4427,
                    "preco_final": 158.4832
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-04T22:13:09.000Z",
                    "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljr2pvjgnftcmacykgcnhqar2rf6bl657nj372zyes5iheremwnyclq",
                    "name_product_resource_id": "SAADESAPPAD (Boot Volume)",
                    "mem": null,
                    "vcpu": null,
                    "performance": 10,
                    "armazenamento": 256,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 48.512,
                    "preco_ativo": 0.4427,
                    "preco_final": 158.4832
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Boot Volume",
            "cost_product_sku": "B91962",
            "name_cost_product_sku": "Serviço de armazenamento de blocos (SSD)",
            "up_front": 0.1355,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 1024,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 40,
            "qtd_horas_dispositivos_total": 1405,
            "qtd_usn_total": 194.05,
            "preco_ativo_total": 1.77,
            "preco_final_total": 633.93,
            "qtd_dispositivos": 4
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T17:17:12.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyu43pxlivktbb452v5gedtlyskjw5gg7c2yzcv7uwaha",
                    "name_product_resource_id": "SAAIBASTWIN",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 283,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 140.651,
                    "preco_ativo": 1.6236,
                    "preco_final": 459.4909
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B88318",
            "name_cost_product_sku": "Licença Windows (por vCPU)",
            "up_front": null,
            "on_demand": 0.2485,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 2,
            "mem_total": 8,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 283,
            "qtd_usn_total": 140.65,
            "preco_ativo_total": 1.62,
            "preco_final_total": 459.49,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2021-10-21T12:19:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cych7houmvtpeqnun6zfozh4knlkn3jfjfiacuhzkwzxs5a",
                    "name_product_resource_id": "SAAHMLDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-11-26T22:40:08.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycybp2ghm4chsq66kldqnb4qf47pk4gso7jvsoyrfktg2q",
                    "name_product_resource_id": "SAADESDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-12-20T15:33:50.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycu3iqykjvdq55gpe5rzssdljzicghxpqf57ddelt3t3oa",
                    "name_product_resource_id": "SAAPRDDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-09-23T23:17:59.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycsfx7minm255mtyg3a4phbx7rnp57tn2lbxjkdsyqkufa",
                    "name_product_resource_id": "DIVPRDDBWIN-V2",
                    "mem": 64,
                    "vcpu": 8,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 274.8928,
                    "preco_ativo": 2.1588,
                    "preco_final": 898.0436
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Compute",
            "cost_product_sku": "B88318",
            "name_cost_product_sku": "Licença Windows para SQL Server (por vCPU )",
            "up_front": 0.0826,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 20,
            "mem_total": 154,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1664,
            "qtd_usn_total": 687.23,
            "preco_ativo_total": 5.4,
            "preco_final_total": 2245.11,
            "qtd_dispositivos": 4
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T16:11:43.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyh52avaiu3x7c52cvscie36lu2hlrrinkf57xnludgra",
                    "name_product_resource_id": "SAAPRDAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E4.Flex",
                    "dt_criacao": "2021-09-01T15:35:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycjzkhfoag6f2dmpjama2qyaubily567ah5tsapekj4s5a",
                    "name_product_resource_id": "ATVDCUPRDAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-10-21T11:41:10.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycrgyc2eiaantlamqwgt3hppg6v5kzfnx2ardv4gqoqdma",
                    "name_product_resource_id": "SAAHMLAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 157,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 25.9364,
                    "preco_ativo": 0.5397,
                    "preco_final": 84.7313
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-12-04T22:13:02.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycoctm24yet3qkfubuwun4ub5njks4o7zqni3pesypuoiq",
                    "name_product_resource_id": "SAADESAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B88318",
            "name_cost_product_sku": "Licença Windows (por vCPU)",
            "up_front": 0.0826,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 8,
            "mem_total": 32,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1405,
            "qtd_usn_total": 232.11,
            "preco_ativo_total": 2.16,
            "preco_final_total": 758.26,
            "qtd_dispositivos": 4
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.4",
                    "dt_criacao": "2021-11-05T19:47:30.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyczcmqwbg3ktzvpzshiogubloa7tkgy3cpxtsmy5v7dc2a",
                    "name_product_resource_id": "ATVAPPKHOMP-V9",
                    "mem": 60,
                    "vcpu": 8,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 845.9776,
                    "preco_ativo": 6.6435,
                    "preco_final": 2763.7129
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "lnx",
            "resource_type": "Compute",
            "cost_product_sku": "B88514",
            "name_cost_product_sku": "VM Linux (por vCPU)",
            "up_front": null,
            "on_demand": 0.2542,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 8,
            "mem_total": 60,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 845.98,
            "preco_ativo_total": 6.64,
            "preco_final_total": 2763.71,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2021-10-21T12:19:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cych7houmvtpeqnun6zfozh4knlkn3jfjfiacuhzkwzxs5a",
                    "name_product_resource_id": "SAAHMLDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-11-26T22:40:08.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycybp2ghm4chsq66kldqnb4qf47pk4gso7jvsoyrfktg2q",
                    "name_product_resource_id": "SAADESDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-12-20T15:33:50.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycu3iqykjvdq55gpe5rzssdljzicghxpqf57ddelt3t3oa",
                    "name_product_resource_id": "SAAPRDDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 137.4464,
                    "preco_ativo": 1.0794,
                    "preco_final": 449.0218
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Compute",
            "cost_product_sku": "B88514",
            "name_cost_product_sku": "VM Windows (por vCPU)",
            "up_front": 0.0826,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 12,
            "mem_total": 90,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1248,
            "qtd_usn_total": 412.34,
            "preco_ativo_total": 3.24,
            "preco_final_total": 1347.07,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2021-10-21T12:19:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cych7houmvtpeqnun6zfozh4knlkn3jfjfiacuhzkwzxs5a",
                    "name_product_resource_id": "SAAHMLDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 275.808,
                    "preco_ativo": 2.1659,
                    "preco_final": 901.0335
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-11-26T22:40:08.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycybp2ghm4chsq66kldqnb4qf47pk4gso7jvsoyrfktg2q",
                    "name_product_resource_id": "SAADESDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 275.808,
                    "preco_ativo": 2.1659,
                    "preco_final": 901.0335
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard2.2",
                    "dt_criacao": "2020-12-20T15:33:50.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycu3iqykjvdq55gpe5rzssdljzicghxpqf57ddelt3t3oa",
                    "name_product_resource_id": "SAAPRDDBWIN",
                    "mem": 30,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 275.808,
                    "preco_ativo": 2.1659,
                    "preco_final": 901.0335
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Compute",
            "cost_product_sku": "B91373",
            "name_cost_product_sku": "VM SQL Server STD (por vRAM)",
            "up_front": 0.0221,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 12,
            "mem_total": 90,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1248,
            "qtd_usn_total": 827.42,
            "preco_ativo_total": 6.5,
            "preco_final_total": 2703.1,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-11T14:17:38.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycqgimoaknjiadjndirc2yasv7w4tbx5ejrjvnbnsoj4xa",
                    "name_product_resource_id": "oke-csfgl5gkdca-nvukdixjlpa-scme6v46q5a-0",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 211.4944,
                    "preco_ativo": 1.6609,
                    "preco_final": 690.9282
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyc3fqt5iobzvf6wnf2cn2x6lnwky3vj6l6ipr4cfp4hgcq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-2",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 211.4944,
                    "preco_ativo": 1.6609,
                    "preco_final": 690.9282
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycj25atnifyfjxbzh2t66q6f6a6jnetrhpamnpf5riirqq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-0",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 211.4944,
                    "preco_ativo": 1.6609,
                    "preco_final": 690.9282
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-12-23T09:13:29.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycakqn3sxw2ukw4ak6alviz7jc6oxuin43f7ay2nhlctnq",
                    "name_product_resource_id": "oke-cqirqfti5lq-npcfiaqciuq-sxxpwfwp74a-0",
                    "mem": 32,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 406,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 412.8208,
                    "preco_ativo": 3.3218,
                    "preco_final": 1348.6387
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycv5rmadh4yxvkwglueec4u4dza3uwxvkg4y3w66go77ra",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-1",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 211.4944,
                    "preco_ativo": 1.6609,
                    "preco_final": 690.9282
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "lnx",
            "resource_type": "Compute",
            "cost_product_sku": "B92306",
            "name_cost_product_sku": "VM Linux (por vCPU)",
            "up_front": null,
            "on_demand": 0.2542,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 12,
            "mem_total": 100,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 2070,
            "qtd_usn_total": 1258.8,
            "preco_ativo_total": 9.97,
            "preco_final_total": 4112.35,
            "qtd_dispositivos": 5
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T17:17:12.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyu43pxlivktbb452v5gedtlyskjw5gg7c2yzcv7uwaha",
                    "name_product_resource_id": "SAAIBASTWIN",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 283,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 140.651,
                    "preco_ativo": 1.6236,
                    "preco_final": 459.4909
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B92306",
            "name_cost_product_sku": "VM Windows (por vCPU)",
            "up_front": null,
            "on_demand": 0.2485,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 2,
            "mem_total": 8,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 283,
            "qtd_usn_total": 140.65,
            "preco_ativo_total": 1.62,
            "preco_final_total": 459.49,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyctehn2qh5dwa5bctfcjq73kwlnxbstzoddqrredfinphq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-1",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 32.448,
                    "preco_ativo": 0.2548,
                    "preco_final": 106.0039
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyckw56s4fyxuhisvd7jnvrva52ao75iw2rw3d3eq2odpba",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-2",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 32.448,
                    "preco_ativo": 0.2548,
                    "preco_final": 106.0039
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cych33iuvljddi53bsqij3zbarr67znrmvgprzoa2dc4zqq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-0",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 32.448,
                    "preco_ativo": 0.2548,
                    "preco_final": 106.0039
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "lnx",
            "resource_type": "Compute",
            "cost_product_sku": "B92306",
            "name_cost_product_sku": "VM Linux (por vCPU)",
            "up_front": 0.039,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 6,
            "mem_total": 60,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1248,
            "qtd_usn_total": 97.34,
            "preco_ativo_total": 0.76,
            "preco_final_total": 318.01,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-12-04T22:13:02.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycoctm24yet3qkfubuwun4ub5njks4o7zqni3pesypuoiq",
                    "name_product_resource_id": "SAADESAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-10-21T11:41:10.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycrgyc2eiaantlamqwgt3hppg6v5kzfnx2ardv4gqoqdma",
                    "name_product_resource_id": "SAAHMLAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 157,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 25.9364,
                    "preco_ativo": 0.5397,
                    "preco_final": 84.7313
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T16:11:43.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyh52avaiu3x7c52cvscie36lu2hlrrinkf57xnludgra",
                    "name_product_resource_id": "SAAPRDAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B92306",
            "name_cost_product_sku": "VM Windows (por vCPU)",
            "up_front": 0.0826,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 6,
            "mem_total": 24,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 989,
            "qtd_usn_total": 163.38,
            "preco_ativo_total": 1.62,
            "preco_final_total": 533.75,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycv5rmadh4yxvkwglueec4u4dza3uwxvkg4y3w66go77ra",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-1",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 540.8,
                    "preco_ativo": 4.247,
                    "preco_final": 1766.7323
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycj25atnifyfjxbzh2t66q6f6a6jnetrhpamnpf5riirqq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-0",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 540.8,
                    "preco_ativo": 4.247,
                    "preco_final": 1766.7323
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-26T23:47:20.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyc3fqt5iobzvf6wnf2cn2x6lnwky3vj6l6ipr4cfp4hgcq",
                    "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-2",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 540.8,
                    "preco_ativo": 4.247,
                    "preco_final": 1766.7323
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-11T14:17:38.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycqgimoaknjiadjndirc2yasv7w4tbx5ejrjvnbnsoj4xa",
                    "name_product_resource_id": "oke-csfgl5gkdca-nvukdixjlpa-scme6v46q5a-0",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 216.32,
                    "preco_ativo": 1.6988,
                    "preco_final": 706.6929
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-12-23T09:13:29.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycakqn3sxw2ukw4ak6alviz7jc6oxuin43f7ay2nhlctnq",
                    "name_product_resource_id": "oke-cqirqfti5lq-npcfiaqciuq-sxxpwfwp74a-0",
                    "mem": 32,
                    "vcpu": 4,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 406,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 844.48,
                    "preco_ativo": 6.7951,
                    "preco_final": 2758.8204
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "lnx",
            "resource_type": "Compute",
            "cost_product_sku": "B92307",
            "name_cost_product_sku": "VM Linux (por vRAM)",
            "up_front": null,
            "on_demand": 0.065,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 12,
            "mem_total": 100,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 2070,
            "qtd_usn_total": 2683.2,
            "preco_ativo_total": 21.23,
            "preco_final_total": 8765.71,
            "qtd_dispositivos": 5
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T17:17:12.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyu43pxlivktbb452v5gedtlyskjw5gg7c2yzcv7uwaha",
                    "name_product_resource_id": "SAAIBASTWIN",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 283,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 86.032,
                    "preco_ativo": 0.9931,
                    "preco_final": 281.0568
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B92307",
            "name_cost_product_sku": "VM Windows (por vRAM)",
            "up_front": null,
            "on_demand": 0.038,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 2,
            "mem_total": 8,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 283,
            "qtd_usn_total": 86.03,
            "preco_ativo_total": 0.99,
            "preco_final_total": 281.06,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyctehn2qh5dwa5bctfcjq73kwlnxbstzoddqrredfinphq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-1",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 37.44,
                    "preco_ativo": 0.294,
                    "preco_final": 122.3122
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cyckw56s4fyxuhisvd7jnvrva52ao75iw2rw3d3eq2odpba",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-2",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 37.44,
                    "preco_ativo": 0.294,
                    "preco_final": 122.3122
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-11-27T00:41:21.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cych33iuvljddi53bsqij3zbarr67znrmvgprzoa2dc4zqq",
                    "name_product_resource_id": "oke-cqwenbvgvqw-nywcnlcmiyd-sc34p3ebioq-0",
                    "mem": 20,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 37.44,
                    "preco_ativo": 0.294,
                    "preco_final": 122.3122
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "lnx",
            "resource_type": "Compute",
            "cost_product_sku": "B92307",
            "name_cost_product_sku": "VM Linux (por vRAM)",
            "up_front": 0.0045,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 6,
            "mem_total": 60,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 1248,
            "qtd_usn_total": 112.32,
            "preco_ativo_total": 0.88,
            "preco_final_total": 366.94,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-09-23T23:17:59.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycsfx7minm255mtyg3a4phbx7rnp57tn2lbxjkdsyqkufa",
                    "name_product_resource_id": "DIVPRDDBWIN-V2",
                    "mem": 64,
                    "vcpu": 8,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 223.6416,
                    "preco_ativo": 1.7563,
                    "preco_final": 730.6117
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "sql",
            "resource_type": "Compute",
            "cost_product_sku": "B92307",
            "name_cost_product_sku": "VM Windows (por vRAM)",
            "up_front": 0.0084,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 8,
            "mem_total": 64,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 223.64,
            "preco_ativo_total": 1.76,
            "preco_final_total": 730.61,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2021-10-21T11:41:10.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycrgyc2eiaantlamqwgt3hppg6v5kzfnx2ardv4gqoqdma",
                    "name_product_resource_id": "SAAHMLAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 157,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 10.5504,
                    "preco_ativo": 0.2195,
                    "preco_final": 34.467
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-12-04T22:13:02.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycoctm24yet3qkfubuwun4ub5njks4o7zqni3pesypuoiq",
                    "name_product_resource_id": "SAADESAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 27.9552,
                    "preco_ativo": 0.2195,
                    "preco_final": 91.3265
                },
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E3.Flex",
                    "dt_criacao": "2020-11-26T16:11:43.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycyh52avaiu3x7c52cvscie36lu2hlrrinkf57xnludgra",
                    "name_product_resource_id": "SAAPRDAPPAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 27.9552,
                    "preco_ativo": 0.2195,
                    "preco_final": 91.3265
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B92307",
            "name_cost_product_sku": "VM Windows (por vRAM)",
            "up_front": 0.0084,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 6,
            "mem_total": 24,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 989,
            "qtd_usn_total": 66.46,
            "preco_ativo_total": 0.66,
            "preco_final_total": 217.12,
            "qtd_dispositivos": 3
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E4.Flex",
                    "dt_criacao": "2021-09-01T15:35:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycjzkhfoag6f2dmpjama2qyaubily567ah5tsapekj4s5a",
                    "name_product_resource_id": "ATVDCUPRDAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 68.7232,
                    "preco_ativo": 0.5397,
                    "preco_final": 224.5109
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B93113",
            "name_cost_product_sku": "VM Windows (por vCPU)",
            "up_front": 0.0826,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 2,
            "mem_total": 8,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 68.72,
            "preco_ativo_total": 0.54,
            "preco_final_total": 224.51,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "VM.Standard.E4.Flex",
                    "dt_criacao": "2021-09-01T15:35:47.000Z",
                    "product_resource_id": "ocid1.instance.oc1.sa-saopaulo-1.antxeljrj43l5cycjzkhfoag6f2dmpjama2qyaubily567ah5tsapekj4s5a",
                    "name_product_resource_id": "ATVDCUPRDAD",
                    "mem": 8,
                    "vcpu": 2,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 27.9552,
                    "preco_ativo": 0.2195,
                    "preco_final": 91.3265
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "win",
            "resource_type": "Compute",
            "cost_product_sku": "B93114",
            "name_cost_product_sku": "VM Windows (por vRAM)",
            "up_front": 0.0084,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 2,
            "mem_total": 8,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 27.96,
            "preco_ativo_total": 0.22,
            "preco_final_total": 91.33,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-12-28T14:51:15.000Z",
                    "product_resource_id": "ocid1.loadbalancer.oc1.sa-saopaulo-1.aaaaaaaacfb4lcmdb3lehmuzs4xz65l53b4x5wmdag3c2nacob3e5qo4odea",
                    "name_product_resource_id": "e22f04af-8bc3-4d7c-9487-35a28866edcd",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 87,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 1.0179,
                    "preco_ativo": 0.0382,
                    "preco_final": 3.3254
                },
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2020-12-06T02:54:15.000Z",
                    "product_resource_id": "ocid1.loadbalancer.oc1.sa-saopaulo-1.aaaaaaaaecug7sqqdyzofu2z6d3ts4hqy5tzi6kf26doeiforgunj3vg6dnq",
                    "name_product_resource_id": "7c392f9a-0b95-412a-9a72-022ccb93ae82",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 4.8672,
                    "preco_ativo": 0.0382,
                    "preco_final": 15.9006
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "",
            "resource_type": "Load Balancer",
            "cost_product_sku": "B88319",
            "name_cost_product_sku": "Serviço de balanceamento de carga",
            "up_front": null,
            "on_demand": 0.0117,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 503,
            "qtd_usn_total": 5.89,
            "preco_ativo_total": 0.08,
            "preco_final_total": 19.23,
            "qtd_dispositivos": 2
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-01-08T14:30:06.000Z",
                    "product_resource_id": "ocid1.loadbalancer.oc1.sa-saopaulo-1.aaaaaaaazxplerua6otkiin3wobdxq7fximztwdoo7pz66eiwr77divntcaq",
                    "name_product_resource_id": "a87a7678-9404-406a-a609-738e22b77d65",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 4.8672,
                    "preco_ativo": 0.0382,
                    "preco_final": 15.9006
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "",
            "resource_type": "Load Balancer",
            "cost_product_sku": "B88319",
            "name_cost_product_sku": "Serviço de balanceamento de carga",
            "up_front": 0.0117,
            "on_demand": null,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 4.87,
            "preco_ativo_total": 0.04,
            "preco_final_total": 15.9,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": null,
                    "dt_criacao": "2021-12-27T23:51:35.000Z",
                    "product_resource_id": "ocid1.loadbalancer.oc1.sa-saopaulo-1.aaaaaaaavy7sqdahvhtekpjwtpfuilhs33jnyfcuoqxq3reibp2o4qkfcnra",
                    "name_product_resource_id": "8f6b2a24-229b-4880-a35f-6a0df0c602d8",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 4.8672,
                    "preco_ativo": 0.0382,
                    "preco_final": 15.9006
                }
            ],
            "tag_ambiente": "hml",
            "tag_sku": "",
            "resource_type": "Loas Balancer",
            "cost_product_sku": "B88319",
            "name_cost_product_sku": "Serviço de balanceamento de carga",
            "up_front": null,
            "on_demand": 0.0117,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 4.87,
            "preco_ativo_total": 0.04,
            "preco_final_total": 15.9,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "",
                    "dt_criacao": "2021-03-26T13:28:57.000Z",
                    "product_resource_id": "grjcjrg5acih/FileServerSaa2",
                    "name_product_resource_id": "FileServerSaa2",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": 0.603072471917,
                    "qtd_horas_dispositivos": 416,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": null,
                    "qtd_usn": 0.0239,
                    "preco_ativo": null,
                    "preco_final": 0.0782
                }
            ],
            "tag_ambiente": "prd",
            "tag_sku": "obs",
            "resource_type": "object storage",
            "cost_product_sku": "B91628",
            "name_cost_product_sku": "Serviço de armazenamento de objetos",
            "up_front": null,
            "on_demand": 0.0397,
            "usn": "3.2668866",
            "armazenamento_total": 0.603072471917,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 416,
            "qtd_usn_total": 0.02,
            "preco_ativo_total": 0,
            "preco_final_total": 0.08,
            "qtd_dispositivos": 1
        },
        {
            "data": [
                {
                    "environment_id": 1,
                    "shape": "",
                    "dt_criacao": "2021-08-10T19:11:29.000Z",
                    "product_resource_id": "ocid1.ipsecconnection.oc1.sa-saopaulo-1.aaaaaaaaxkwv5t3q42qzsrav2gasxxldckr745hq4dmgw3lo2wsulncjimta",
                    "name_product_resource_id": "VPN-SQUADRA-GCP",
                    "mem": null,
                    "vcpu": null,
                    "performance": null,
                    "armazenamento": null,
                    "qtd_horas_dispositivos": 432,
                    "qtd_dispositivos": 1,
                    "dados_trafegados_gb": 0.489286581054,
                    "qtd_usn": 0.0473,
                    "preco_ativo": null,
                    "preco_final": 0.1546
                }
            ],
            "tag_ambiente": "vpn",
            "tag_sku": "vpn",
            "resource_type": "vpn",
            "cost_product_sku": null,
            "name_cost_product_sku": "Serviço de VPN",
            "up_front": null,
            "on_demand": 0.0967,
            "usn": "3.2668866",
            "armazenamento_total": 0,
            "vcpu_total": 0,
            "mem_total": 0,
            "performance_total": 0,
            "qtd_horas_dispositivos_total": 432,
            "qtd_usn_total": 0.05,
            "preco_ativo_total": 0,
            "preco_final_total": 0.15,
            "qtd_dispositivos": 1
        }
    ],
    "total": {
        "armazenamento": 15378.6,
        "vcpu": 126,
        "mem": 898,
        "performance": 400,
        "dispositivos": 80,
        "qtd_horas_dispositivos": 31512,
        "qtd_usn": 11701.74,
        "preco_ativo": 79.69,
        "preco_final": 38228.26
    }
};

export default BILLING_REPORTS;