import { faker } from "@faker-js/faker";

export const PROJECT_MOCK = [
    {
        id: 1,
        name: faker.company.name(),
        limit: '',
        cost_center: '',
        observations: '',
        company_id: 1,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        name: faker.company.name(),
        limit: '',
        cost_center: '',
        observations: '',
        company_id: 1,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]