import MenuService from './MenuService'
import CompanyService, { CompanyMockService } from './CompanyService'
import ProductService, { ProductMockService } from './ProductService'
import ProjectService, { ProjectMockService } from './ProjectService'
import ContractService, { ContractMockService } from './ContractService'
import UsageAlertService, { UsageAlertMockService } from './UsageAlertService'
import GroupsService, { GroupsMockService } from './GroupsService'
import ProfileService, { ProfileMockService } from './ProfileService'
import UserService, { UserMockService } from './UserService'
import SettingsService, { SettingsMockService } from './SettingsService'
import AlertService from './AlertService'
import MobileService from './MobileService';

const getServiceInstance = (serviceClass, mockServiceClass) => {
    const isMockEnabled = true;
    return isMockEnabled
        ? new mockServiceClass()
        : new serviceClass();
}

export const companyServiceInstance = getServiceInstance(CompanyService, CompanyMockService);
export const productServiceInstance = getServiceInstance(ProductService, ProductMockService);
export const projectServiceInstance = getServiceInstance(ProjectService, ProjectMockService);
export const contractServiceInstance = getServiceInstance(ContractService, ContractMockService);
export const usageAlertServiceInstance = getServiceInstance(UsageAlertService, UsageAlertMockService);
export const groupsServiceInstance = getServiceInstance(GroupsService, GroupsMockService);
export const profileServiceInstance = getServiceInstance(ProfileService, ProfileMockService);
export const userServiceInstance = getServiceInstance(UserService, UserMockService);
export const settingsServiceInstance = getServiceInstance(SettingsService, SettingsMockService);
export const alertServiceInstance = new AlertService();
export const mobileServiceInstance = new MobileService();

export {
    MenuService
}
