<template>
  <div class="templates-container">
    <div class="template-list">
      <h3>Meus templates</h3>

      <div class="new-template-section">
        <AppButton @click="createTemplate()" label="Novo Template" />
      </div>

      <table>
        <tr>
          <th style="width: 50px; max-width: 50px; min-width: 50px;">Tipo</th>
          <th>Template</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;">Criado Em</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;">Última Atualização</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of templatesList" class="table-hover-row">
          <td>{{data.type}}</td>
          <td>{{data.templateKey}}</td>
          <td>{{data.createdAt}}</td>
          <td>{{data.updatedAt}}</td>
          <td style="text-align: right;">
            <a>Editar</a>
            <a class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script src="./ListNotificationTemplates.js"/>
<style scoped src="./ListNotificationTemplates.scss" lang="scss" />