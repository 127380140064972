<template>
  <div id="billing">
    <div class="table-filter-container">
      <div class="section-billing">
        <Logo class="app-logo-content"/>
        <div class="section-billing-body">
          <h1>INFORMAÇÕES DE FATURA</h1>
          <div class="grid-container">
            <div class="grid-item section-billing-details">
              <p><b>Invoice:</b> {{billingInfo.information?.invoice}}</p>
              <p><b>Data de emissão:</b> {{ billingInfo.information?.emission_date }}</p>
              <p><b>Ciclo de faturamento:</b> {{ billingInfo.information?.invoicing_cicle }}</p>
              <p><b>Moeda de transação:</b> {{ billingInfo.information?.currency }}</p>
              <p><b>Cliente destinado:</b> {{ billingInfo.information?.destination_client }}</p>
              <p><b>CNPJ:</b> {{ billingInfo.information?.cnpj }}</p>
              <p><b>E-mail destinado:</b> {{ billingInfo.information?.destination_email }}</p>
            </div>
            <div class="grid-item" style="display: flex; align-items: center; justify-content: center;">
              <div class="card-billing">
                <h2>Total Faturado no Período</h2>
                  <p>{{ billingInfo.information?.total }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section-billing-footer">
          <p>Av. Carlos Gomes, 1672 8° andar, Petrópolis, Porto Alegre/RS, CEP 903213</p>
        </div>
      </div>
      <div class="table-filters">

        <table class="filter-table-section">
          <tr>
            <td :colspan="showMoreFilters ? 3 : 8">
              <div :class="{ 'filter-actions': true, 'filter-actions-compressed': !showMoreFilters }">

                <div class="filter-option-container">
                  <div @click="toggleColumnOptions()"
                       v-bind:class="{
                          'filter-option': true,
                          'select-shadow': true,
                          'filter-option-active': showColumnOptions
                       }">
                    <div class="filter-icon">
                      <Icon type="table-filled"/>
                    </div>
                    <span class="filter-label">Colunas:</span>
                    <span class="filter-data">{{getActiveColumnsLength()}}/{{getColumnsLength()}}</span>
                  </div>

                  <div v-if="showColumnOptions" class="filter-expanded shadow-4">
                    <div class="search-bar">
                      <div class="search-container">
                        <div class="icon-container">
                          <Icon type="search"/>
                        </div>
                        <input type="text" placeholder="Buscar..."/>
                      </div>
                    </div>

                    <div class="list-container">
                      <div v-for="column in getColumns()"
                           @click="toggleColumn(column.key)"
                           class="list-item">
                        <input v-bind:id="column.key" type="checkbox" v-bind:checked="column.active"/>
                        <span>{{column.label}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

      </div>
    </div>

    <hr style="margin-top: 14px" class="billing-horizontal-rule"/>

    <!-- END FILTERS -->

    <div class="table-content">

      <div class="table-head-container">
        <div class="thc-row">
          <template v-for="data in getColumns()">
            <div v-if="data.active"
                 v-bind:class="{ 'thc-cell': true, 'table-cell-right-text': data.pullRight  }"
                 v-bind:style="{ 'max-width': data.width || '0', 'min-width': data.width, 'width': data.width,  }">
              {{data.label}}
            </div>
          </template>
        </div>
      </div>

      <div class="billing-table-container">
        <div class="billing-table">

          <template v-for="group in billing.groups">
            <!-- billing-table-sub-head -->
            <div v-for="innerData in group.data" class="billing-table-row">
              <div v-if="isColumnActive('LINE')" class="billing-table-cell">{{innerData?.linha}}</div>
              <div v-if="isColumnActive('SERVICE_ORDER')" class="billing-table-cell">{{group?.ordem_servico}}</div>
              <div v-if="isColumnActive('ALOCATION')" class="billing-table-cell">{{innerData?.alocacao}}</div>
              <div v-if="isColumnActive('PRODUCT')" class="billing-table-cell">{{innerData?.produto}}</div>
              <div v-if="isColumnActive('DESCRIPTION')" class="billing-table-cell">{{innerData?.descricao}}</div>
              <div v-if="isColumnActive('TYPE')" class="billing-table-cell">{{innerData?.tipo}}</div>
              <div v-if="isColumnActive('QUANTITY')" class="billing-table-cell">{{innerData?.quantidade}}</div>
              <div v-if="isColumnActive('UNITY_PRICE')" class="billing-table-cell">{{innerData?.preco_unitario}}</div>
              <div v-if="isColumnActive('RECCURENCY')" class="billing-table-cell">{{innerData?.recorrencia}}</div>
              <div v-if="isColumnActive('NOT-RECCURENCY')" class="billing-table-cell">{{innerData?.nao_recorrencia}}</div>
              <div v-if="isColumnActive('SUBTOTAL-PRICE')" class="billing-table-cell">{{innerData?.subtotal}}</div>
            </div>
          </template>

        </div>
      </div>

    </div>

    <div class="export-options">Total Faturado: R$ {{billing.total.preco_final}}</div>
    <div class="export-options">
      <AppButton label="Exportar" />
    </div>

  </div>
</template>

<script src="./Billing.js"/>
<style scoped src="./Billing.scss" lang="scss"/>
