import { Chart, registerables } from 'chart.js';
import { CHART_BAR_DATA, CHART_DOUGHNUT_DATA } from '../../../__mocks/ChartDataMock';

Chart.register(...registerables);

function generateChart({ canvasId, type, data, options }) {
    const chartDataType = {
        'bar': CHART_BAR_DATA,
        'doughnut': CHART_DOUGHNUT_DATA
    };
    const defaultChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                maxWidth: 100,
                position: 'right',
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                            text: `${data} ${chart.data.labels[i]}`,
                            fillStyle: datasets[0].backgroundColor[i],
                            lineWidth: 0
                        }))
                    },
                    usePointStyle: true,
                    boxPadding: 1,
                    padding: 10,
                }
            }
        }
    };
    return new Chart(canvasId, {
        type,
        data: data ?? chartDataType[type],
        options: options ?? defaultChartOptions
    });
}

export default {
    name: 'Chart',

    props: {
        chartId: String,
        chartType: {
            type: String,
            default: 'bar'
        },
        chartData: {
            type: Object,
            default: null
        },
        chartOptions: {
            type: Object,
            default: null
        }
    },

    mounted() {
        generateChart({
            canvasId: this.chartId,
            type: this.chartType,
            data: this.chartData,
            options: this.chartOptions
        });
    },
}