import { Menu, Navbar, Breadcrumb, Alert } from './components'
import { alertServiceInstance as alertService } from './services'

export default {
    components: { Menu, Navbar, Breadcrumb, Alert },
    methods: {
        canShowMenu() {
            return this.$route.path !== "/" && this.$route.path !== "/forgot-password" && this.$route.name !== "MobileBlock" &&  this.$route.name !== "ResetPassword";
        },

        getAppRouterContentClass() {
            const canShowMenu = this.canShowMenu();
            return {
                'router-fixed-menu': canShowMenu && this.isMenuFixed,
                'router-hover-menu': canShowMenu && !this.isMenuFixed,
                'smooth-menu-transition': true
            }
        },

        getPrefixMenuClass(prefix) {
            return {
                [`${prefix}-fixed-menu`]: this.isMenuFixed,
                [`${prefix}-hover-menu`]:! this.isMenuFixed,
                'smooth-menu-transition': true
            }
        },

        onMenuExpand(isFixed) {
            this.isMenuFixed = isFixed;
        },

        checkNewAlerts() {
            this.alerts = alertService.alerts;
        }
    },

    data: () => ({
        isMenuFixed: false,
        alerts: alertService.alerts,
        checkNewAlertsIntervalRef: null
    }),

    mounted() {
        this.checkNewAlertsIntervalRef = setInterval(this.checkNewAlerts, 50);
    },

    unmounted() {
        if (this.checkNewAlertsIntervalRef) clearInterval(this.checkNewAlertsIntervalRef);
    }
};
