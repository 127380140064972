import Icon from "../../../general/icon/Icon.vue";
import {
    userServiceInstance as userService
} from '../../../../services';

export default {
    name: "UserOptions",
    emits: ['on-option-selected'],
    components: {
        Icon
    },
    methods: {
        logout() {
            userService.logout();
            this.$emit('on-option-selected', this.isMenuFixed);
            this.$router.push({ path: '/', replace: true });
        },

        openSecurityOption() {
            this.$emit('on-option-selected', this.isMenuFixed);
            this.$router.push('/settings/security');
        },

        openProfileOption() {
            // TODO
            this.$emit('on-option-selected', this.isMenuFixed);
        }
    },
    data() {
        return {
            userInfo: null
        }
    },
    mounted() {
        this.userInfo = userService.loggedUser
    }
}