import { AppButton, Slide } from "../../../components";
import {
    groupsServiceInstance as groupsService,
    companyServiceInstance as companyService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterGroup",
    components: { AppButton, Slide },
    methods: {
        async saveGroup() {
            let alertDesc = '';
            if (this.isEdit) {
                await groupsService.updateById(this.group, this.group.id);
                alertDesc = 'Grupo atualizado!';
            } else {
                await groupsService.create(this.group);
                alertDesc = 'Grupo criado!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/groups/list');
        }
    },
    data() {
        return {
            group: {},
            companies: [],
            isEdit: false
        }
    },
    async mounted() {
        this.companies = await companyService.findAll();
        const groupId = this.$route.query.id;
        if (groupId) {
            this.group = (await groupsService.findOneById(groupId)) ?? {};
            this.isEdit = true;
        }
    }
}