import { AppInput, AppButton, Logo } from "../../components";
import {
    alertServiceInstance as alertService,
    userServiceInstance as userService
} from "../../services";

export default {
    name: "ResetPassword",
    components: {
        AppInput,
        AppButton,
        Logo
    },
    data() {
        return {
            email: null,
            password: null,
            re_password: null,
            token: null,
        };
    },
    mounted() {
        if (userService.isLogged()) {
            this.user = null;
            this.$router.push('home');
        }
    },
    methods: {
        async send(event) {
            await this.sendForgotData();
        },

        async sendForgotData() {
            try {
                if(this.password === this.re_password){
                    this.token = this.$route.query.token;
                    this.email = this.$route.params.email;
                    await userService.resetPassword(this.email, this.password, this.token);
                    alertService.success({
                        desc: 'Senha redefinida com sucesso!'
                    });
                } else {
                    alertService.error({
                        desc: 'As senhas devem ser iguais!'
                        }
                    );
                }
            } catch (err) {
                console.error(err?.message);
                alertService.error(
                    this.parseErrorMessage(err?.response?.data)
                );
            }
        },
        async onEnter(source) {
            switch (source) {
                case 'email':
                case 'pass':
                    await this.login();
                    return;
            }
        },
        getLeftPanelClass() {
            return {
                'left-panel': true,
                'login-panel': true,
            }
        },

        getRightPanelClass() {
            return {
                'right-panel': true,
                'login-panel': true,
            }
        },
    }
}
