import { Icon, Filter, AppButton } from "../../components";
import BILLING_REPORTS from '../../__mocks/BillingReportMock';

const BILLING_REPORTS_COLUMNS = [
    { active: true,  key: 'COST_SKU', label: 'Cost Product SKU', width: '120px' },
    { active: false, key: 'REFERENCE_DATE', label: 'Dt. Referência (USN)' },
    { active: false, key: 'CREATION_DATE', label: 'Dt. Criação' },
    { active: false, key: 'ENVIRONMENT', label: 'Ambiente' },
    { active: false, key: 'TAG_SKU', label: 'Tag SKU' },
    { active: true,  key: 'PRODUCT_SERVICE', label: 'Product Service' },
    { active: false, key: 'NAME_SKU', label: 'Name Cost Product SKU' },
    { active: false, key: 'SHAPE', label: 'Shape' },
    { active: false, key: 'MEM', label: 'Mem' },
    { active: false, key: 'VCPU', label: 'vCPU' },
    { active: false, key: 'DISCO', label: 'Performance Disco' },
    { active: false, key: 'GB', label: 'GB' },
    { active: false, key: 'DEVICE_HOURS', label: 'Qtd. Horas Dispositivos' },
    { active: false, key: 'DEVICE_QUANTITY', label: 'Qtd. Dispositivos' },
    { active: false, key: 'UP_FRONT', label: 'Up Front' },
    { active: false, key: 'ON_DEMAND', label: 'On Demand' },
    { active: true,  key: 'USN', label: 'USN', pullRight: true },
    { active: false, key: 'USN_QUANTITY', label: 'Qtd. de USN' },
    { active: false, key: 'TOTAL', label: 'Total em R$' }
];

export default {
    name: "BillingReport",

    components: {
        Icon,
        Filter,
        AppButton
    },

    methods: {
        isColumnActive(columnKey) {
            return this.billingReportsColumns.get(columnKey).active;
        },

        getColumns() {
            return Array.from(this.billingReportsColumns.values());
        },

        getActiveColumnsLength() {
            return Array.from(this.billingReportsColumns.values())
                .filter(column => column.active)
                .length;
        },

        getColumnsLength() {
            return Array.from(this.billingReportsColumns.values())
                .length;
        },

        toggleColumn(columnKey) {
            const column = this.billingReportsColumns.get(columnKey);
            if (column) {
                column.active = !column.active;
            }
        },

        toggleColumnOptions() {
            this.showColumnOptions = !this.showColumnOptions;
        },

        toggleMoreFilters() {
            this.showMoreFilters = !this.showMoreFilters;
        }
    },

    data() {
        return {
            billingReportsColumns: new Map(BILLING_REPORTS_COLUMNS.map(column => [column.key, column])),
            billingReports: BILLING_REPORTS,
            showColumnOptions: false,
            showMoreFilters: false
        }
    }
}