import { isProxy, toRaw } from 'vue';

export default class BaseMockService {
    constructor(serviceName, initialData) {
        this._serviceName = serviceName;
        this._dataList = [];
        this._storageKey = `${this._serviceName}-list`;

        this._syncStorage();

        console.log(`started ${this._serviceName} mock service`);

        if (initialData && Array.isArray(initialData)) {
            initialData = initialData.filter(data => {
                return data.id != null && !this._dataList.some(dl => dl.id === data.id);
            });

            if (initialData.length) {
                this._dataList.push(...initialData);
                this._saveStorage();

                console.log(`initial data added for ${this._serviceName} mock service`);
            }
        }
    }

    async findAll() {
        return this._dataList;
    }

    async findOne(query) {
        query = this._sanitizeQuery(query);
        return this._dataList.find(data => {
            return Object
                .keys(query)
                .every(key => data[key] === query[key]);
        });
    }

    async findOneById(id) {
        return this.findOne({ id });
    }

    async create(data) {
        data = this._unproxyObject(data);
        data.id = this._getNextAvailableId();
        this._dataList.push(data);
        this._saveStorage();
    }

    async delete(query) {
        const index = this._findIndex(query);
        this._dataList.splice(index, 1);
        this._saveStorage();
    }

    async deleteById(id) {
        return this.delete({ id });
    }

    async update(data, query) {
        data = this._unproxyObject(data);
        const index = this._findIndex(query);
        if (index > -1) {
            const sourceData = this._dataList[index];
            data.id = sourceData.id;
            this._dataList[index] = data;
            this._saveStorage();
            return data;
        }
        return null;
    }

    async updateById(data, id) {
        return this.update(data, { id });
    }

    _sanitizeQuery(query) {
        query = this._unproxyObject(query);
        if (!isNaN(query?.id)) query.id = Number(query.id);
        return query;
    }

    _getNextAvailableId() {
        let lastData = this._dataList
            .sort((a, b) => a.id - b.id)
            .at(-1);
        lastData = this._unproxyObject(lastData);
        return (lastData?.id ?? 0) + 1;
    }

    _unproxyObject(object) {
        if (isProxy(object)) return toRaw(object);
        else return object;
    }

    _findIndex(query) {
        query = this._sanitizeQuery(query);
        console.log(query);
        return this._dataList.findIndex(data => {
            return Object
                .keys(query)
                .every(key => data[key] === query[key]);
        });
    }

    _syncStorage() {
        const rawData = localStorage.getItem(this._storageKey);
        this._dataList = JSON.parse(rawData) ?? [];
    }

    _saveStorage() {
        const rawDate = JSON.stringify(this._dataList);
        localStorage.setItem(this._storageKey, rawDate);
    }
}