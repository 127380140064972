<template>
  <div :class="getAlertClass()">
    <div class="alert-data">
      <div class="alert-icon">
        <Icon :type="getAlertIcon()" />
      </div>
      <div class="alert-text">
        <span class="alert-title">{{data.title}}</span>
        <span class="alert-desc">{{data.desc}}</span>
      </div>
      <div @click="closeAlert()" class="alert-close">
        <Icon type="x-filled" />
      </div>
    </div>
    <div v-if="data.expiresIn"
         :id="'alert-progress-bar-' + data.id"
         :style="{ width: `${progressPercentage}%` }"
         class="alert-progress-bar"></div>
  </div>
</template>

<script src="./Alert.js" />
<style scoped src="./Alert.scss" lang="scss" />