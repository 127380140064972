<template>
  <div class="login-container logo-bg">
    <div :class="getLeftPanelClass()">

      <div class="input-container">
        <div class="login-logo">
          <!--
          <img alt="" src="../../assets/logo-transparent.png"/>
          -->
          <Logo class="login-logo-content"/>
        </div>

        <!--
        <h2>Faça login</h2>
        -->

        <AppInput @keyup.enter="onEnter('email')" style="margin-top: 60px" v-model="email" class="login-input" label="Email" type="email" placeholder="" />
        <AppInput @keyup.enter="onEnter('pass')" style="margin-bottom: 30px" v-model="password" class="login-input" label="Senha" type="password" placeholder=""/>

        <AppButton @click="login" label="Entrar" />

        <a class="forgot-password" href="/#/forgot-password">Esqueceu a senha?</a>
      </div>

      <ConfirmMFA @on-confirm="sendMFAData" @on-cancel="cancelMFA" v-if="confirmMFA"/>

    </div>
    <div :class="getRightPanelClass()">

      <!--
      <div class="description-panel">
        <h2 class="title">
          Lorem ipsum dolor sit amet!
        </h2>
        <h3 class="desc">
          Suspendisse nisl justo, pretium vitae efficitur id, tincidunt et leo. Pellentesque sollicitudin convallis est, a cursus felis efficitur et. Proin lacinia lorem id risus maximus, venenatis pulvinar nibh venenatis. Vestibulum laoreet imperdiet nisi in mollis.
        </h3>
      </div>
      -->

    </div>
  </div>
</template>

<script src="./Login.js" />
<style scoped src="./Login.scss" lang="scss" />
