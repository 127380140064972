const ON_PREMISE_REPORTS = {
    "data": [
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-01T15:55:35.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrbql7hh3az24ybefw2wmowrf6tizim35arpmra57qbpjkjda4gxra",
            "name_product_resource_id": "ATVAPPKHOMP-V7-PUB (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 120,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 22.74,
            "preco_ativo": 0.4427,
            "preco_final": 74.289,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "detail": "INC",
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-05T18:38:11.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljryy6eg3zqj5bx7lf72heiqvpam2oqv2yzzu7bgqhxeoqmvcztmkga",
            "name_product_resource_id": "ATVAPPKHOMP-V8-PUB (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 160,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 30.32,
            "preco_ativo": 0.4427,
            "preco_final": 99.052,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "OFF",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-05T19:47:30.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrq2a7cydathxiuluxrjn7pcpai56656m7bedqh7vxpykewrcmrppa",
            "name_product_resource_id": "ATVAPPKHOMP-V9 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 198,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 37.521,
            "preco_ativo": 0.4427,
            "preco_final": 122.5769,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-05T21:05:46.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrnkxpt5sm6vfvoim4fwgmepytq277fudijvjxjtfuy5iqapoeokxq",
            "name_product_resource_id": "oke-csrp4hm7qwq-nm5u775r4ba-sc34p3ebioq-0 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "detail": "DEC",
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-05T21:05:46.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrrjgwavgl7mw2trhiaod6kud3v7i44cgu5lza6agjc4s6uuodxica",
            "name_product_resource_id": "oke-csrp4hm7qwq-nm5u775r4ba-sc34p3ebioq-1 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "OFF",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-11T14:17:38.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljroe4ssnhiovimnbcjj72njrbtmdevqq2yse4eqxgpgiydnbrmvs4a",
            "name_product_resource_id": "oke-csfgl5gkdca-nvukdixjlpa-scme6v46q5a-0 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-26T23:47:20.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrk6mbkydm5uputjjtmsnczrmkvc5gz74prcqnwsmbet6jmdjkh7pq",
            "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-2 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-26T23:47:20.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrtnmfsrdg6pwfqgsmswniu5i2hkwzrbrez4ldt35fwhtz6cczxonq",
            "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-0 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-11-26T23:47:20.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrxok3pcbua7ifdojy7llwbpnxxdga6prjk3ry5f37oso2zswgfvza",
            "name_product_resource_id": "oke-cydcojsmyyd-n2tkmbzhezw-sxxpwfwp74a-1 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2020-11-27T22:50:29.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrenden5frylqxtyxmy7qflguzbqcaptx53kyuoaielacof2va56rq",
            "name_product_resource_id": "SAAIBASTLNX (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-12-06T11:54:34.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrcmqz4ir6e2twcil2nx2dixfzn6bqbxhkuyxvrzknonqfh6o45wnq",
            "name_product_resource_id": "ATVESMASTERNODE (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        },
        {
            "environment_id": 1,
            "shape": null,
            "dt_criacao": "2021-12-23T09:13:29.000Z",
            "product_resource_id": "ocid1.bootvolume.oc1.sa-saopaulo-1.abtxeljrhin32ognmiaazm5eygsfkphzvegj4xuwnqx3m3gqyk4zld774sdq",
            "name_product_resource_id": "oke-cqirqfti5lq-npcfiaqciuq-sxxpwfwp74a-0 (Boot Volume)",
            "mem": 4,
            "vcpu": 2,
            "performance": 10,
            "armazenamento": 50,
            "qtd_horas_dispositivos": 416,
            "qtd_dispositivos": 1,
            "dados_trafegados_gb": null,
            "qtd_usn": 9.475,
            "preco_ativo": 0.4427,
            "preco_final": 30.9538,
            "ip_address": "172.16.22.11",
            "hostname": "hostname",
            "operation_system": "CentOS 7 (64-bit)",
            "status": "ON",
        }
    ],
};

export default ON_PREMISE_REPORTS;