<template>
  <div id="register-product0-factor">

    <div class="form-input-container">

      <div style="display: inline-block; width: calc(50% - 10px); margin-right: 10px;" class="form-input-section">
        <span>SKU:</span>
        <input type="text" placeholder="Digite o SKU"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Tag SKU:</span>
        <input type="text" placeholder="Digite a Tag SKU"/>
      </div>

      <div style="display: inline-block; width: calc(33% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Up Front:</span>
        <input type="number" placeholder=""/>
      </div>

      <div style="display: inline-block; width: calc(33% - 10px); margin-left: 10px; margin-right: 10px;" class="form-input-section">
        <span>On Demand:</span>
        <input type="number" placeholder=""/>
      </div>

      <div style="display: inline-block; width: calc(33% - 10px); margin-left: 10px;" class="form-input-section">
        <span>USN:</span>
        <input type="text" placeholder="Digite o USN"/>
      </div>

      <div class="form-input-section">
        <span>Data de Referência:</span>
        <input type="date" placeholder="dd/mm/yyyy"/>
      </div>

    </div>

    <div class="form-input-actions">
      <AppButton label="Salvar" />
    </div>

  </div>
</template>

<script>
import { AppButton } from "../../../components";

export default {
  name: "RegisterProductFactor",
  components: { AppButton }
}
</script>

<style scoped>

</style>