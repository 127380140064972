import { MenuService } from "../services";

export const PROFILES_MOCK = [
    {
        id: 1,
        name: 'Admin',
        observations: 'Admin',
        permissions: MenuService.getAllMenuIds(),
        is_admin: true,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]