import { AppInput, AppButton, Logo } from "../../components";

export default {
    name: "MobileBlock",
    components: {
        AppInput,
        AppButton,
        Logo,
    },
    data() {
    },
    methods: {
        getLeftPanelClass() {
            return {
                'left-panel': true,
                'login-panel': true,
            }
        },

        getRightPanelClass() {
            return {
                'right-panel': true,
                'login-panel': true,
            }
        },
    }
}
