<template>
  <div class="app-list-container">
    <div class="app-list">
      <div class="new-entity-section">
        <AppButton @click="openCreate()" label="Novo Grupo" />
      </div>

      <table>
        <tr>
          <th style="width: 20px; max-width: 20px; min-width: 20px;">ID</th>
          <th>Nome</th>
          <th>Empresa</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of groups" class="table-hover-row">
          <td>{{data.id}}</td>
          <td>{{data.name}}</td>
          <td>{{data.company?.name}}</td>
          <td style="text-align: right;">
            <a @click="openEdit(data)">Editar</a>
            <a @click="remove(data)" class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import { AppButton } from "../../../components";
import {
  groupsServiceInstance as groupsService,
  alertServiceInstance as alertService,
} from "../../../services";

export default {
  name: "ListGroups",
  components: {
    AppButton
  },
  methods: {
    openCreate() {
      this.$router.push('/groups/create');
    },
    openEdit({ id }) {
      this.$router.push(`/groups/create?id=${id}`);
    },
    async remove({ id }) {
      await groupsService.deleteById(id);
      alertService.success({ desc: 'Grupo excluído!' });
      await this.load();
    },
    async load() {
      const data = (await groupsService.findAll()) ?? [];
      this.groups.splice(0, this.groups.length);
      this.groups.push(...data);
    }
  },
  data: () => ({
    groups: []
  }),
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped lang="scss">

</style>