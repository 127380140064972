export default class AlertService {
    constructor() {
        this._alerts = new Map();
        this._defaultExpiresIn = 10000;
    }

    get alerts() {
        return Array.from(this._alerts.values());
    }

    success({ title = 'Sucesso', desc, expiresIn = this._defaultExpiresIn }) {
        this.addAlert({
            id:  Date.now(),
            title,
            desc,
            expiresIn,
            type: 'success'
        });
    }

    warning({ title = 'Atenção!', desc, expiresIn = this._defaultExpiresIn }) {
        this.addAlert({
            id:  Date.now(),
            title,
            desc,
            expiresIn,
            type: 'warning'
        });
    }

    error({ title = 'Erro', desc, expiresIn = this._defaultExpiresIn }) {
        this.addAlert({
            id:  Date.now(),
            title,
            desc,
            expiresIn,
            type: 'error'
        });
    }

    info({ title, desc, expiresIn = this._defaultExpiresIn }) {
        this.addAlert({
            id:  Date.now(),
            title,
            desc,
            expiresIn,
            type: 'info'
        });
    }

    addAlert(data) {
        if (data.expiresIn) {
            data.timeoutRef = setTimeout(() => this.clear(data.id), data.expiresIn);
        }
        this._alerts.set(data.id, {
            ...data,
            startedAt: Date.now()
        });
    }

    clear(id) {
        const data = this._alerts.get(id);
        if (data && data.timeoutRef) {
            clearTimeout(data.timeoutRef);
        }

        this._alerts.delete(id);
    }

    clearAll() {
        this._alerts.forEach((value, key) => {
            if (value.timeoutRef) {
                clearTimeout(value.timeoutRef);
            }
        });
        this._alerts.clear();
    }
}