import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { PROFILES_MOCK } from "../__mocks/ProfileMock";

export class ProfileMockService extends BaseMockService {
    constructor() {
        super('profiles', PROFILES_MOCK);
    }
}

export default class ProfileService extends BaseService {
    constructor() {
        super('profiles', {
            name: String,
            observations: String,
            permissions: Array,
            is_admin: Boolean,
            is_active: Boolean
        });
    }

    async findAll() {
        const result = await super.get('/v1/profiles');
        return result.data;
    }

    async findOne(query) {
        throw new Error('ProfileService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/profiles/${id}`);
    }

    async create(data) {
        return super.post('/v1/profiles', data);
    }

    async update(data, companyId) {
        throw new Error('ProfileService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/profiles/${id}`, data);
    }
}