<template>
  <div id="register-group">

    <div class="form-input-container">
      <div class="form-input-section">
        <span>Nome:</span>
        <input v-model="group.name" type="text" placeholder="Digite o nome do grupo"/>
      </div>

      <div class="form-input-section">
        <span>Empresa:</span>
        <select v-model="group.company_id">
          <option v-for="company in companies" :value="company.id" selected>{{company.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveGroup()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterGroup.js"/>
<style scoped src="./RegisterGroup.scss" lang="scss"/>