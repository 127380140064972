import { faker } from '@faker-js/faker';

export const PRODUCTS_MOCK = [
    {
        id: 1,
        name: faker.commerce.productName(),
        company_id: 1,
        sku: `${faker.number.int()}`,
        type: '',
        unit_value: faker.number.int(),
        unit: '',
        price: faker.number.int(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        name: faker.commerce.productName(),
        company_id: 1,
        sku: `${faker.number.int()}`,
        type: '',
        unit_value: faker.number.int(),
        unit: '',
        price: faker.number.int(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 3,
        name: faker.commerce.productName(),
        company_id: 1,
        sku: `${faker.number.int()}`,
        type: '',
        unit_value: faker.number.int(),
        unit: '',
        price: faker.number.int(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 4,
        name: faker.commerce.productName(),
        company_id: 2,
        sku: `${faker.number.int()}`,
        type: '',
        unit_value: faker.number.int(),
        unit: '',
        price: faker.number.int(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]