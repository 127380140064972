import { faker } from '@faker-js/faker';

export const CONTRACTS_MOCK = [
    {
        id: 1,
        details: faker.lorem.words(),
        observations: faker.lorem.words(),
        company_id: 1,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        details: faker.lorem.words(),
        observations: faker.lorem.words(),
        company_id: 1,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]