import { faker } from '@faker-js/faker';

export const COMPANIES_MOCK = [
    {
        id: 1,
        name: faker.company.name(),
        address: faker.location.streetAddress(),
        zipcode: faker.location.zipCode(),
        city: faker.location.city(),
        state: faker.location.state(),
        cellphone: faker.phone.number(),
        email: faker.internet.email(),
        financial_email: faker.internet.email(),
        technical_email: faker.internet.email(),
        commercial_email: faker.internet.email(),
        logo_url: faker.internet.url(),
        company_url: faker.internet.url(),
        observations: faker.lorem.words(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        name: faker.company.name(),
        address: faker.location.streetAddress(),
        zipcode: faker.location.zipCode(),
        city: faker.location.city(),
        state: faker.location.state(),
        cellphone: faker.phone.number(),
        email: faker.internet.email(),
        financial_email: faker.internet.email(),
        technical_email: faker.internet.email(),
        commercial_email: faker.internet.email(),
        logo_url: faker.internet.url(),
        company_url: faker.internet.url(),
        observations: faker.lorem.words(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 3,
        name: faker.company.name(),
        address: faker.location.streetAddress(),
        zipcode: faker.location.zipCode(),
        city: faker.location.city(),
        state: faker.location.state(),
        cellphone: faker.phone.number(),
        email: faker.internet.email(),
        financial_email: faker.internet.email(),
        technical_email: faker.internet.email(),
        commercial_email: faker.internet.email(),
        logo_url: faker.internet.url(),
        company_url: faker.internet.url(),
        observations: faker.lorem.words(),
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]