import BaseService from './base-service';
import BaseMockService from "./base-mock-service";

export class UsageAlertMockService extends BaseMockService {
    constructor() {
        super('usage-alerts', []);
    }
}

export default class UsageAlertService extends BaseService {
    constructor() {
        super('usage-alerts', {
            percentage_threshold: Number,
            value_threshold: Number,
            product_id: Number,
            project_id: Number,
            is_active: Boolean
        });
    }

    async findAll() {
        const result = await super.get('/v1/usage-alerts');
        return result.data;
    }

    async findOne(query) {
        throw new Error('UsageAlertService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/usage-alerts/${id}`);
    }

    async create(data) {
        return super.post('/v1/usage-alerts', data);
    }

    async update(data, companyId) {
        throw new Error('UsageAlertService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/usage-alerts/${id}`, data);
    }
}