<template>
  <div id="register-contract">

    <div class="form-input-container">

      <div class="form-input-section">
        <span>Contrato:</span>
        <input id="contract_file" ref="contract_file" type="file" @change="handleFileUpload" placeholder="Selecione o arquivo"/>
      </div>

      <div style="display: inline-block; width: calc(70% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Empresa:</span>
        <select v-model="contract.company_id" >
          <option v-for="company in companies" :value="company.id" selected>{{company.name}}</option>
        </select>
      </div>

      <div class="form-input-section">
        <span>Contrato número:</span>
        <textarea v-model="contract.number" placeholder="Digite"/>
      </div>

      <div class="form-input-section">
        <span>Data da Assinatura:</span>
        <input type="date" v-model="contract.signature"/>
      </div>

      <div class="form-input-section">
        <span>Vigência:</span>
        <input type="date" v-model="contract.initialValidity"/><span style="text-align: center"> a </span><input type="date" v-model="contract.finalValidity"/>
      </div>

      <div style="display: inline-block; width: calc(70% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Índice para reajuste:</span>
        <select v-model="contract.indexReajust" >
          <option selected>INPC - Índice Nacional de Preços ao Consumidor</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(30% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Ativo:</span>
        <label class="switch">
          <input v-model="contract.is_active" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveContract()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterContract.js" />
<style scoped src="./RegisterContract.scss" lang="scss" />