<template>
  <div class="app-list-container">
    <div class="app-list">
      <div class="new-entity-section">
        <AppButton @click="openCreate()" label="Nova Empresa" />
      </div>

      <table>
        <tr>
          <th style="width: 20px; max-width: 20px; min-width: 20px;">ID</th>
          <th>Nome</th>
          <th>Endereço</th>
          <th>CEP</th>
          <th>Cidade</th>
          <th>Estado</th>
          <th>Telefone</th>
          <th>Email Principal</th>
          <th>Email Financeiro</th>
          <th>Email Técnico</th>
          <th>Email Comercial</th>
          <th>URL</th>
          <th>Observações</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of companies" class="table-hover-row">
          <td>{{data.id}}</td>
          <td>{{data.name}}</td>
          <td>{{data.address}}</td>
          <td>{{data.zipcode}}</td>
          <td>{{data.city}}</td>
          <td>{{data.state}}</td>
          <td>{{data.cellphone}}</td>
          <td>{{data.email}}</td>
          <td>{{data.financial_email}}</td>
          <td>{{data.technical_email}}</td>
          <td>{{data.commercial_email}}</td>
          <td>{{data.company_url}}</td>
          <td>{{data.observations}}</td>
          <td style="text-align: right;">
            <a @click="openEdit(data)">Editar</a>
            <a @click="remove(data)" class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import { AppButton } from "../../../components";
import {
  companyServiceInstance as companyService,
  alertServiceInstance as alertService
} from "../../../services";

export default {
  name: "ListCompanies",
  components: {
    AppButton
  },
  methods: {
    openCreate() {
      this.$router.push('/companies/create');
    },
    openEdit({ id }) {
      this.$router.push(`/companies/create?id=${id}`);
    },
    async remove({ id }) {
      await companyService.deleteById(id);
      alertService.success({ desc: 'Empresa excluída!' });
      await this.load();
    },
    async load() {
      const data = (await companyService.findAll()) ?? [];
      this.companies.splice(0, this.companies.length);
      this.companies.push(...data);
    }
  },
  data: () => ({
    companies: []
  }),
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped lang="scss">

</style>