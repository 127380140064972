<template>
  <div class="login-container logo-bg">
    <div :class="getLeftPanelClass()">

      <div class="input-container">
        <div class="login-logo">
          <Logo class="login-logo-content"/>
        </div>
        <AppInput @keyup.enter="onEnter('password')" style="margin-top: 60px" v-model="password" class="login-input" label="Password" type="password" placeholder="" />
        <AppInput @keyup.enter="onEnter('re-password')" style="margin-top: 60px" v-model="re_password" class="login-input" label="Re-Password" type="password" placeholder="" />
        <AppButton @click="send" label="Enviar" />
        <a class="forgot-password" href="/">Realizar Login</a>
      </div>

    </div>
    <div :class="getRightPanelClass()">

    </div>
  </div>
</template>

<script src="./ResetPassword.js" />
<style scoped src="../login/Login.scss" lang="scss" />
