const BILLING_INFORMATION = {
    "information":
        {
            "invoice": "#0001",
            "emission_date": "2022,01 de Abril",
            "invoicing_cicle": "01.03.2022 a 31.03.2022",
            "currency": "BRL",
            "destination_client": "BPOO DCX",
            "cnpj": "07.303.726/0001-42",
            "destination_email": "bpoo@datacentrics.com.br",
            "total": "R$ 1.510,00"
        }
};

export default BILLING_INFORMATION;
