<template>
  <div id="register-project">

    <div class="form-input-container">

      <div class="form-input-section">
        <span>Nome:</span>
        <input v-model="project.name" type="text" placeholder="Digite o nome do projeto"/>
      </div>

      <div style="display: inline-block; width: calc(30% - 20px); margin-right: 10px;" class="form-input-section">
        <span>Limite:</span>
        <input v-model="project.limit" type="text" placeholder="Digite o Limite do projeto"/>
      </div>

      <div style="display: inline-block; width: calc(70% - 20px); margin-left: 10px;" class="form-input-section">
        <span>Centro de Custo:</span>
        <input v-model="project.cost_center" type="text" placeholder="Digite o centro de custo"/>
      </div>

      <div class="form-input-section">
        <span>Observações:</span>
        <textarea v-model="project.observations" placeholder="Digite as observações"/>
      </div>

      <div style="display: inline-block; width: calc(70% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Empresa:</span>
        <select v-model="project.company_id" >
          <option v-for="company in companies" :value="company.id" selected>{{company.name}}</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(30% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Ativo:</span>
        <label class="switch">
          <input v-model="project.is_active" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveProject()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterProject.js" />
<style scoped src="./RegisterProject.scss" lang="scss" />