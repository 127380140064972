<template>
  <div id="register-profile">

    <div class="form-input-container">

      <div class="form-input-section">
        <span>Nome:</span>
        <input v-model="profile.name" type="text" placeholder="Digite o nome do perfil"/>
      </div>

      <div class="form-input-section">
        <span>Observações:</span>
        <textarea v-model="profile.observations" placeholder="Digite as observações"/>
      </div>

      <div class="form-input-section">
        <span>Ativo:</span>
        <label class="switch">
          <input v-model="profile.is_active" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="permission-container">
      <h3>Permissões</h3>

      <div class="permissions">

        <template v-for="menu in menuItems">
          <div :class="getMenuOptionClass(menu)"
               @click="selectMenuOption(menu)">

            <div v-if="menu.iconType" class="option-icon">
              <Icon :type="menu.iconType"/>
            </div>

            <div class="option-label">
              <b>{{menu.label}}</b>
              <small>{{menu.routerLink}}</small>
            </div>

            <div v-if="menu.selected" class="selected-icon">
              <Icon type="check-filled"/>
            </div>
          </div>

          <template v-if="menu.subMenus?.length" v-for="subMenu in menu.subMenus">

            <div :class="getSubMenuOptionClass(subMenu)"
                 @click="selectSubMenuOption(subMenu, menu)">
              <div class="option-icon">
                <Icon type="chevron-double-right-filled"/>
              </div>
              <div class="option-label">
                <b>{{subMenu.label}}</b>
                <small>{{subMenu.routerLink}}</small>
              </div>
              <div v-if="subMenu.selected" class="selected-icon">
                <Icon type="check-filled"/>
              </div>
            </div>

            <template v-if="subMenu.subMenus?.length" v-for="subMenuSub in subMenu.subMenus">
              <div :class="getSubMenuSubOptionClass(subMenuSub)"
                   @click="selectSubMenuSubOption(subMenuSub, [menu, subMenu])">
                <div class="option-icon">
                  <Icon type="chevron-double-right-filled"/>
                </div>
                <div class="option-label">
                  <b>{{subMenuSub.label}}</b>
                  <small>{{subMenuSub.routerLink}}</small>
                </div>
                <div v-if="subMenuSub.selected" class="selected-icon">
                  <Icon type="check-filled"/>
                </div>
              </div>
            </template>

          </template>

        </template>

      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveProfile()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterProfile.js"/>
<style scoped src="./RegisterProfile.scss" lang="scss" />