<template>
  <div class="app-list-container">
    <div class="app-list">
      <div class="new-entity-section">
        <AppButton @click="openCreate()" label="Nova Perfil de Acesso" />
      </div>

      <table>
        <tr>
          <th style="width: 20px; max-width: 20px; min-width: 20px;">ID</th>
          <th>Nome</th>
          <th>Observações</th>
          <th>Ativo</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of profiles" class="table-hover-row">
          <td>{{data.id}}</td>
          <td>{{data.name}}</td>
          <td>{{data.observations}}</td>
          <td>{{data.is_active ? 'Sim' : 'Não'}}</td>
          <td style="text-align: right;">
            <a @click="openEdit(data)">Editar</a>
            <a @click="remove(data)" class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import { AppButton } from "../../../components";
import {
  profileServiceInstance as profileService,
  alertServiceInstance as alertService
} from "../../../services";

export default {
  name: "ListProfiles",
  components: {
    AppButton
  },
  methods: {
    openCreate() {
      this.$router.push('/profiles/create');
    },
    openEdit({ id }) {
      this.$router.push(`/profiles/create?id=${id}`);
    },
    async remove({ id }) {
      await profileService.deleteById(id);
      alertService.success({ desc: 'Perfil de Acesso excluído!' });
      await this.load();
    },
    async load() {
      const data = (await profileService.findAll()) ?? [];
      this.profiles.splice(0, this.profiles.length);
      this.profiles.push(...data);
    }
  },
  data: () => ({
    profiles: []
  }),
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped lang="scss">

</style>