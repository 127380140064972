export default class MenuService {
    static getAllMenuIds() {
        return this.MenuItems
            .reduce((acc, menu) => {
                if (menu.id) acc.push(menu.id);

                if (menu?.subMenus?.length) {
                    menu.subMenus.forEach((subMenu) => {
                       if (subMenu.id) acc.push(subMenu.id);
                       if (subMenu.subMenus?.length) {
                           subMenu.subMenus.forEach((sub) => {
                               if (sub.id) acc.push(sub.id);
                           });
                       }
                    });
                }

                return acc;
            }, []);
    }

    static get MenuItems() {
        return [
            { id: 'home', label: 'Home', iconType: 'home-filled', routerLink: '/home', hasSubMenu: false },
            { id: 'services', label: 'Serviços', iconType: 'star-filled', routerLink: '/home', hasSubMenu: false },
            {
                id: 'records',
                label: 'Cadastros',
                iconType: 'archive-filled',
                routerLink: null,
                hasSubMenu: true,
                subMenus: [
                    {
                        id: 'records.company',
                        label: 'Empresas',
                        subMenus: [
                            { id: 'records.company.create', label: 'Cadastrar', routerLink: '/companies/create' },
                            { id: 'records.company.list', label: 'Listar', routerLink: '/companies/list' }
                        ]
                    },

                    {
                        id: 'records.groups',
                        label: 'Grupos',
                        subMenus: [
                            { id: 'records.groups.create', label: 'Cadastrar', routerLink: '/groups/create' },
                            { id: 'records.groups.list', label: 'Listar', routerLink: '/groups/list' }
                        ]
                    },

                    {
                        id: 'records.projects',
                        label: 'Projetos',
                        subMenus: [
                            { id: 'records.projects.create', label: 'Cadastrar', routerLink: '/projects/create' },
                            { id: 'records.projects.list', label: 'Listar', routerLink: '/projects/list' }
                        ]
                    },

                    {
                        id: 'records.products',
                        label: 'Produtos',
                        subMenus: [
                            { id: 'records.products.create', label: 'Cadastrar', routerLink: '/products/create' },
                            { id: 'records.products.list', label: 'Listar', routerLink: '/products/list' }
                        ]
                    },

                    {
                        id: 'records.usage-alerts',
                        label: 'Alertas de Uso',
                        subMenus: [
                            { id: 'records.usage-alerts.create', label: 'Cadastrar', routerLink: '/usage-alerts/create' },
                            { id: 'records.usage-alerts.list', label: 'Listar', routerLink: '/usage-alerts/list' }
                        ]
                    },

                    {
                        id: 'records.users',
                        label: 'Usuários',
                        subMenus: [
                            { id: 'records.users.create', label: 'Cadastrar', routerLink: '/users/create' },
                            { id: 'records.users.list', label: 'Listar', routerLink: '/users/list' }
                        ]
                    },

                    /*
                    {
                        label: 'Fatores de Produto',
                        subMenus: [
                            { label: 'Cadastrar', routerLink: '/product-factors/create' },
                            { label: 'Listar', routerLink: '/product-factors/list' }
                        ]
                    }
                     */

                    {
                        id: 'records.contracts',
                        label: 'Contratos',
                        subMenus: [
                            { id: 'records.contracts.create', label: 'Cadastrar', routerLink: '/contracts/create' },
                            { id: 'records.contracts.list', label: 'Listar', routerLink: '/contracts/list' }
                        ]
                    },

                    {
                        id: 'records.profiles',
                        label: 'Perfis de Acesso',
                        subMenus: [
                            { id: 'records.profiles.create', label: 'Cadastrar', routerLink: '/profiles/create' },
                            { id: 'records.profiles.list', label: 'Listar', routerLink: '/profiles/list' }
                        ]
                    },

                ]
            },
            {
                id: 'reports',
                label: 'Relatórios',
                iconType: 'document-report-filled',
                hasSubMenu: true,
                routerLink: null,
                subMenus: [
                    { id: 'reports.billing-report', label: 'Análise de custo', routerLink: '/billing-report' },
                    { id: 'reports.usage-report', label: 'Análise de uso', routerLink: null },
                    { id: 'reports.billing', label: 'Faturamento', routerLink: '/billing' }
                ]
            },
            {
                id: 'costs',
                label: 'Custos',
                iconType: 'currency-dollar-filled',
                routerLink: null,
                hasSubMenu: true,
                subMenus: [
                    { id: 'costs.cloud', label: 'Nuvem', routerLink: '/costs/cloud-report' },
                    { id: 'costs.on-premise', label: 'On-Premise', routerLink: '/costs/on-premise-report' },
                ]
            },
            { id: 'usage', label: 'Utilização', iconType: 'trending-up-filled', routerLink: null, hasSubMenu: false },
            { id: 'contract', label: 'Contrato', iconType: 'document-duplicate-filled', routerLink: null, hasSubMenu: true,
            subMenus: [
                { id: 'contract.detailing', label: 'Detalhamento', routerLink: '/contract/detailing' }
            ]},
            { id: 'performance', label: 'Performance e Consumo', iconType: 'shield-check-filled', routerLink: null, hasSubMenu: false },
            {
                id: 'control-panel',
                label: 'Meu Painel de Controle',
                subMenuTitle: 'Painel de Controle',
                iconType: 'cog-filled',
                routerLink: null,
                hasSubMenu: true,
                subMenus: [
                    { id: 'control-panel.notification-templates', label: 'Personalizar Notificações', routerLink: '/settings/notification-templates/list' }
                ]
            },
            { id: 'alerts', label: 'Alertas', iconType: 'bell-filled', routerLink: null, hasSubMenu: false },
        ];
    }

    static get SettingItems() {
        return [
            {
                label: 'Configurações',
                iconType: 'cog-filled',
                routerLink: null,
                hasSubMenu: true,
                subMenus: [
                    { label: 'Segurança', routerLink: '/settings/security' }
                ]
            },
            {
                label: 'Meu Painel de Controle',
                subMenuTitle: 'Painel de Controle',
                iconType: 'cog-filled',
                routerLink: null,
                hasSubMenu: true,
                subMenus: [
                    {
                        label: 'Personalizar Notificações',
                        subMenus: [
                            { label: 'Novo Template', routerLink: '/settings/notification-templates/create' }
                        ]
                    }
                ]
            },
        ];
    }

    static get AppItems() {
        return [ ...MenuService.MenuItems, ...MenuService.SettingItems ];
    }
}
