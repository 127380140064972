import { faker } from '@faker-js/faker';

export const USERS_MOCK = [
    {
        id: 1,
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
        treatment: 'Sr.',
        profile_id: 1,
        company_id: 1,
        group_id: 1,
        email: faker.internet.email(),
        password: '',
        enable_mfa_setup: false,
        totp: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
    {
        id: 2,
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
        treatment: 'Sr.',
        profile_id: 1,
        company_id: 1,
        group_id: 1,
        email: faker.internet.email(),
        password: '',
        enable_mfa_setup: false,
        totp: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    },
]