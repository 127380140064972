import { AppButton } from "../../../../components";

export default {
    name: "ListNotificationTemplates",
    components: {
        AppButton
    },
    methods: {
        createTemplate() {
            this.$router.push('/settings/notification-templates/create');
        }
    },
    data: () => ({
        templatesList: [
            { type: 'Email', templateKey: 'Alerta', createdAt: '01/01/2022 23:49', updatedAt: '12/01/2022 13:27' },
            { type: 'Email', templateKey: 'Recuperação de Senha', createdAt: '01/01/2022 23:49', updatedAt: '12/01/2022 13:27' },
        ]
    })
}