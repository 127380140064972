import { Icon, Filter, AppButton } from "../../../components";
import ON_PREMISE_REPORTS from '../../../__mocks/OnPremiseReportMock';

const ON_PREMISE_REPORTS_COLUMNS = [
    { active: true, key: 'CREATED_AT', label: 'Data Criação', width: '180px', pullRight: false },
    { active: true, key: 'HOSTNAME', label: 'Hostname', width: '30px', pullRight: false },
    { active: true, key: 'OPERATION_SYSTEM', label: 'Sistema Operacional', width: '180px', pullRight: false },
    { active: true, key: 'MEMORY', label: 'Memória', width: '35px', pullRight: false },
    { active: true, key: 'VCPU', label: 'VCPU', width: '55px', pullRight: false },
    { active: true, key: 'DISK', label: 'Disco', width: '55px', pullRight: false },
    { active: true, key: 'IP_ADDRESS', label: 'Endereço IP', width: '70px', pullRight: false },
    { active: true, key: 'MEMORY_PRICE', label: 'Valor Memória', width: '100px', pullRight: false },
    { active: true, key: 'CPU_PRICE', label: 'Valor CPU', width: '60px', pullRight: false },
    { active: true, key: 'DISK_PRICE', label: 'Valor Disco', width: '80px', pullRight: false },
    { active: true, key: 'STATUS', label: 'Status', width: '40px', pullRight: false },
];

export default {
    name: "OnPremiseReport",

    components: {
        Icon,
        Filter,
        AppButton
    },

    methods: {
        isColumnActive(columnKey) {
            return this.onPremiseReportsColumns.get(columnKey).active;
        },

        getColumns() {
            return Array.from(this.onPremiseReportsColumns.values());
        },

        getActiveColumnsLength() {
            return Array.from(this.onPremiseReportsColumns.values())
                .filter(column => column.active)
                .length;
        },

        getColumnsLength() {
            return Array.from(this.onPremiseReportsColumns.values())
                .length;
        },

        toggleColumn(columnKey) {
            const column = this.onPremiseReportsColumns.get(columnKey);
            if (column) {
                column.active = !column.active;
            }
        },

        toggleColumnOptions() {
            this.showColumnOptions = !this.showColumnOptions;
        },

        toggleMoreFilters() {
            this.showMoreFilters = !this.showMoreFilters;
        }
    },

    data() {
        return {
            onPremiseReportsColumns: new Map(ON_PREMISE_REPORTS_COLUMNS.map(column => [column.key, column])),
            onPremiseReports: ON_PREMISE_REPORTS,
            showColumnOptions: false,
            showMoreFilters: false
        }
    }
}