import { Icon } from "../../index";
import {
    MenuService,
    userServiceInstance as userService
} from "../../../services";

const copyObject = (obj = {}) => JSON.parse(JSON.stringify(obj));

export default {
    name: 'Menu',

    emits: ['expand'],

    components: {
        Icon
    },

    methods: {
        getMenuClass() {
            return {
                'shadow-1': true,
                'fixed-menu': this.isMenuFixed,
                'hover-menu': !this.isMenuFixed
            }
        },

        getMenuOptionClass(menu) {
            return {
                'menu-option': this.subMenuSelected == null,
                'round-menu-option': this.subMenuSelected != null,
                'round-menu-option-active': this.subMenuSelected != null
                    && menu.label === this.subMenuSelected.parentMenu.label
            }
        },

        getSubMenuOptionClass(subMenu) {
            return {
                'sub-menu-option': true,
                'sub-menu-option-active': subMenu.showOptions
            }
        },

        getMenuIconType() {
            return this.isMenuFixed ? 'menu' : 'menu-alt1';
        },

        toggleMenuType() {
            this.isMenuFixed = !this.isMenuFixed;
            this.$emit('expand', this.isMenuFixed);
        },

        selectMenu(menu) {
            const currentRoute = this.$route.path;

            if (menu.hasSubMenu) {
                this.subMenuSelected = {
                    parentMenu: { ...menu, subMenus: undefined },
                    options: menu.subMenus
                        .map(subMenu => {
                            if (subMenu.subMenus?.some(sm => sm.routerLink === currentRoute)) {
                                subMenu.showOptions = true;
                            }
                            return subMenu;
                        })
                };
            } else {
                if (menu.routerLink != null) {
                    this.$router.push(menu.routerLink);
                }
                this.subMenuSelected = null;
            }
        },

        selectSubMenu(subMenu) {
            if (subMenu.subMenus?.length > 0) {
                subMenu.showOptions = !subMenu.showOptions;
            } else if (subMenu.routerLink != null) {
                this.$router.push(subMenu.routerLink);
            }
        },

        closeSubMenu() {
            this.subMenuSelected = null;
        }
    },

    data() {
        return {
            menuItems: [],
            isMenuFixed: false,
            subMenuSelected: null,
            userInfo: null
        }
    },

    mounted() {
        this.userInfo = userService.loggedUser;
        const permissions = this.userInfo?.profile?.permissions;
        this.menuItems = copyObject(MenuService.MenuItems)
            .filter(menu => permissions?.includes(menu.id))
            .map(menu => {
                menu.subMenus = menu.subMenus
                    ?.filter(sm => permissions?.includes(sm.id))
                    .map(sm => {
                        sm.subMenus = sm.subMenus?.filter(smSub => permissions?.includes(smSub.id));
                        return sm;
                    });
                return menu;
            });
    }
}