<template>
  <div id="cloud-report">

    <!-- FILTERS -->

    <div class="table-filter-container">
      <div class="table-filters">

        <table class="filter-table-section">
          <tr>
            <!--
            <td><Filter icon="calendar-filled" label="Empresa" data="All"/></td>
            -->
            <td><Filter icon="calendar-filled" label="Período Inicial" data="Jan 2022"/></td>
            <td><Filter icon="calendar-filled" label="Período Final" data="Fev 2022"/></td>
            <td><Filter icon="calendar-filled" label="Projeto" data="All"/></td>
            <!--
            <td><Filter icon="calendar-filled" label="Ambiente" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Grupo" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Produto" data="Detailed Billing"/></td>
            <td><Filter icon="calendar-filled" label="Dt. Referência (USN)" data="[01/06/2021] R$ 3.26"/></td>
            -->

            <td>
              <div class="link-container action-filters">
                <a @click="toggleMoreFilters">{{showMoreFilters ? 'Esconder' : 'Mais'}} filtros</a>
              </div>
            </td>
          </tr>

          <tr>
            <template v-if="showMoreFilters">
              <td colspan="2"><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
              <td><Filter icon="calendar-filled" label="Filtro" data="Exemplo"/></td>
            </template>

            <td :colspan="showMoreFilters ? 3 : 8">
              <div :class="{ 'filter-actions': true, 'filter-actions-compressed': !showMoreFilters }">

                <div class="filter-option-container">
                  <div @click="toggleColumnOptions()"
                       v-bind:class="{
                          'filter-option': true,
                          'select-shadow': true,
                          'filter-option-active': showColumnOptions
                       }">
                    <div class="filter-icon">
                      <Icon type="table-filled"/>
                    </div>
                    <span class="filter-label">Colunas:</span>
                    <span class="filter-data">{{getActiveColumnsLength()}}/{{getColumnsLength()}}</span>
                  </div>

                  <div v-if="showColumnOptions" class="filter-expanded shadow-4">
                    <div class="search-bar">
                      <div class="search-container">
                        <div class="icon-container">
                          <Icon type="search"/>
                        </div>
                        <input type="text" placeholder="Buscar..."/>
                      </div>
                    </div>

                    <div class="list-container">
                      <div v-for="column in getColumns()"
                           @click="toggleColumn(column.key)"
                           class="list-item">
                        <input v-bind:id="column.key" type="checkbox" v-bind:checked="column.active"/>
                        <span>{{column.label}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!--
                <AppButton label="Gerar Relatório" />
                -->
                <AppButton class="app-button-light" label="Limpar Filtros" />

                <div class="link-container">
                  <!--
                  <a @click="toggleMoreFilters">{{showMoreFilters ? 'Esconder' : 'Mais'}} filtros</a>
                  -->
                  <a>Favoritar seleção</a>
                </div>
              </div>
            </td>
          </tr>
        </table>

      </div>
    </div>

    <div style="display: block; text-align: center; padding: 20px 20px 0 20px; font-weight: bold;">
      Ambiente Nuvem OCI
    </div>

    <hr style="margin-top: 14px" class="billing-horizontal-rule"/>

    <!-- END FILTERS -->

    <div class="table-content">
      <!--
      <div class="table-head-container">
        <div class="thc-row">
          <template v-for="data in getColumns()">
            <div v-if="data.active"
                 v-bind:class="{ 'thc-cell': true, 'table-cell-right-text': data.pullRight  }"
                 v-bind:style="{ 'max-width': data.width || '0', 'min-width': data.width, 'width': data.width }">
              {{data.label}}
            </div>
          </template>
        </div>
      </div>
      -->

      <div class="billing-table-container">
        <div class="billing-table">

          <div class="billing-table-row billing-table-head">
            <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;">

            </div>
            <template v-for="data in getColumns()">
              <div v-if="data.active"
                   v-bind:class="{ 'billing-table-cell': true, 'billing-table-cell-right-text': data.pullRight }"
                   v-bind:style="{ 'max-width': data.width || '0', 'min-width': data.width, 'width': data.width }">
                {{data.label}}
              </div>
            </template>
          </div>


          <template v-for="group in cloudReports.groups">

            <div @click="expandRow(group)" style="cursor: pointer;" class="billing-table-row">
              <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;">
                <div class="table-expand">
                  <Icon v-if="!group._expanded" type="plus-filled"/>
                  <Icon v-if="group._expanded" type="minus-filled"/>
                </div>
              </div>
              <div v-if="isColumnActive('CREATED_AT')" class="billing-table-cell"></div>
              <div v-if="isColumnActive('PRODUCT')" class="billing-table-cell"><b>{{group?.resource_type}}</b></div>
              <div v-if="isColumnActive('ENVIRONMENT')" class="billing-table-cell"><b>{{group?.tag_ambiente}}</b></div>
              <div v-if="isColumnActive('DISK_PERFORMANCE')" class="billing-table-cell"><b>{{group?.performance_total}}</b></div>
              <div v-if="isColumnActive('GB')" class="billing-table-cell"><b>{{group?.armazenamento_total}}</b></div>
              <div v-if="isColumnActive('HOURS_QUANTITY')" class="billing-table-cell"><b>{{group?.qtd_horas_dispositivos_total}}</b></div>
              <div v-if="isColumnActive('QUANTITY')" class="billing-table-cell"><b>{{group?.qtd_dispositivos}}</b></div>
              <div v-if="isColumnActive('UPFRONT')" class="billing-table-cell"><b>{{group?.up_front}}</b></div>
              <div v-if="isColumnActive('ON_DEMAND')" class="billing-table-cell"><b>{{group?.on_demand}}</b></div>
              <div v-if="isColumnActive('USN_QUANTITY')" class="billing-table-cell"><b>{{group?.qtd_usn_total}}</b></div>
              <div v-if="isColumnActive('TOTAL')" class="billing-table-cell table-cell-right-text"><b>R$ {{group?.preco_final_total}}</b></div>
              <div v-if="isColumnActive('USN_VALUE')" class="billing-table-cell table-cell-right-text"><b>R$ {{group?.usn}}</b></div>
            </div>

            <div v-if="group._expanded" v-for="innerData in group.data" class="billing-table-row">
              <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;"></div>
              <div v-if="isColumnActive('CREATED_AT')" class="billing-table-cell">{{innerData?.dt_criacao}}</div>
              <div v-if="isColumnActive('PRODUCT')" class="billing-table-cell">{{innerData?.name_product_resource_id}}</div>
              <div v-if="isColumnActive('ENVIRONMENT')" class="billing-table-cell">{{group?.tag_ambiente}}</div>
              <div v-if="isColumnActive('DISK_PERFORMANCE')" class="billing-table-cell">{{innerData?.performance}}</div>
              <div v-if="isColumnActive('GB')" class="billing-table-cell">{{innerData?.armazenamento}}</div>
              <div v-if="isColumnActive('HOURS_QUANTITY')" class="billing-table-cell">{{innerData?.qtd_horas_dispositivos_total}}</div>
              <div v-if="isColumnActive('QUANTITY')" class="billing-table-cell">{{innerData?.qtd_dispositivos}}</div>
              <div v-if="isColumnActive('UPFRONT')" class="billing-table-cell">{{group?.up_front}}</div>
              <div v-if="isColumnActive('ON_DEMAND')" class="billing-table-cell">{{group?.on_demand}}</div>
              <div v-if="isColumnActive('USN_QUANTITY')" class="billing-table-cell">{{innerData?.qtd_usn}}</div>
              <div v-if="isColumnActive('TOTAL')" class="billing-table-cell table-cell-right-text">R$ {{innerData?.preco_final}}</div>
              <div v-if="isColumnActive('USN_VALUE')" class="billing-table-cell table-cell-right-text">R$ {{group?.usn}}</div>
            </div>

          </template>


          <div class="billing-table-row billing-table-footer">
            <div class="billing-table-cell" style="max-width: 20px; min-width: 20px; width: 20px;">Total: </div>
            <div v-if="isColumnActive('CREATED_AT')" class="billing-table-cell"></div>
            <div v-if="isColumnActive('PRODUCT')" class="billing-table-cell"></div>
            <div v-if="isColumnActive('ENVIRONMENT')" class="billing-table-cell"></div>
            <div v-if="isColumnActive('DISK_PERFORMANCE')" class="billing-table-cell">{{cloudReports?.total?.performance}}</div>
            <div v-if="isColumnActive('GB')" class="billing-table-cell">{{cloudReports?.total?.armazenamento}}</div>
            <div v-if="isColumnActive('HOURS_QUANTITY')" class="billing-table-cell">{{cloudReports?.total?.qtd_horas_dispositivos}}</div>
            <div v-if="isColumnActive('QUANTITY')" class="billing-table-cell">{{cloudReports?.total?.dispositivos}}</div>
            <div v-if="isColumnActive('UPFRONT')" class="billing-table-cell"></div>
            <div v-if="isColumnActive('ON_DEMAND')" class="billing-table-cell"></div>
            <div v-if="isColumnActive('USN_QUANTITY')" class="billing-table-cell">{{cloudReports?.total?.qtd_usn}}</div>
            <div v-if="isColumnActive('TOTAL')" class="billing-table-cell table-cell-right-text">R$ {{cloudReports?.total?.preco_final}}</div>
            <div v-if="isColumnActive('USN_VALUE')" class="billing-table-cell table-cell-right-text"></div>
          </div>

        </div>
      </div>
    </div>

    <div class="export-options">
      <AppButton label="Gerar termo" />
    </div>

  </div>
</template>

<script src="./CloudReport.js" />
<style scoped src="./CloudReport.scss" lang="scss"/>