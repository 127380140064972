const BILLING = {
    "groups": [
        {
            "data": [
                {
                    "linha": "1",
                    "alocacao": "On-Promise",
                    "produto": "Produto X",
                    "descricao": "Descrição Produto X",
                    "tipo": "n/a",
                    "quantidade": 54,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "--",
                    "nao_recorrencia": "R$ 54.00",
                    "subtotal": "R$ 54.00",
                },
                {
                    "linha": "1.1",
                    "alocacao": "On-Promise",
                    "produto": "",
                    "descricao": "",
                    "tipo": "",
                    "quantidade": "",
                    "preco_unitario": "",
                    "recorrencia": "",
                    "nao_recorrencia": "",
                    "subtotal": "",
                }
            ],
            "ordem_servico": "000123",
        },
        {
            "data": [
                {
                    "linha": "2",
                    "alocacao": "On-Promise",
                    "produto": "Produto Y",
                    "descricao": "Descrição Produto Y",
                    "tipo": "n/a",
                    "quantidade": 21,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "R$ 21.00",
                    "nao_recorrencia": "--",
                    "subtotal": "R$ 21.00",
                },
                {
                    "linha": "2.1",
                    "alocacao": "On-Promise",
                    "produto": "",
                    "descricao": "",
                    "tipo": "",
                    "quantidade": "",
                    "preco_unitario": "",
                    "recorrencia": "",
                    "nao_recorrencia": "",
                    "subtotal": "",
                },
                {
                    "linha": "2.2",
                    "alocacao": "On-Promise",
                    "produto": "",
                    "descricao": "",
                    "tipo": "",
                    "quantidade": "",
                    "preco_unitario": "",
                    "recorrencia": "",
                    "nao_recorrencia": "",
                    "subtotal": "",
                }
            ],
            "ordem_servico": "000124",
        },
        {
            "data": [
                {
                    "linha": "3",
                    "alocacao": "On-Promise",
                    "produto": "Produto Z",
                    "descricao": "Descrição Produto Z",
                    "tipo": "n/a",
                    "quantidade": 1,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "R$ 21.00",
                    "nao_recorrencia": "--",
                    "subtotal": "R$ 21.00",
                }
            ],
            "ordem_servico": "000125",
        },
        {
            "data": [
                {
                    "linha": "4",
                    "alocacao": "On-Promise",
                    "produto": "Produto A",
                    "descricao": "Descrição Produto A",
                    "tipo": "n/a",
                    "quantidade": 1275,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "R$ 1.275,00",
                    "nao_recorrencia": "--",
                    "subtotal": "R$ 1.275,00",
                }
            ],
            "ordem_servico": "000126",
        },
        {
            "data": [
                {
                    "linha": "5",
                    "alocacao": "Oracle Cloud",
                    "produto": "Produto G",
                    "descricao": "Descrição Produto G",
                    "tipo": "n/a",
                    "quantidade": 60,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "--",
                    "nao_recorrencia": "R$ 60.00",
                    "subtotal": "R$ 60.00",
                }
            ],
            "ordem_servico": "000127",
        },
        {
            "data": [
                {
                    "linha": "6",
                    "alocacao": "Oracle Cloud",
                    "produto": "Produto H",
                    "descricao": "Descrição Produto H",
                    "tipo": "n/a",
                    "quantidade": 4,
                    "preco_unitario": "R$ 1,00",
                    "recorrencia": "--",
                    "nao_recorrencia": "R$ 4.00",
                    "subtotal": "R$ 4.00",
                }
            ],
            "ordem_servico": "000128",
        },
    ],
    "total": {
        "preco_final": 38228.26
    }
};

export default BILLING;
