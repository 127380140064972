import { Icon } from '../../index';
import { alertServiceInstance as alertService } from '../../../services';

export default {
    name: "Alert",
    components: {
        Icon
    },
    methods: {
        getAlertIcon() {
            const alertTypeSwitch = {
                'success': 'check-circle-filled',
                'warning': 'exclamation-filled',
                'error': 'exclamation-circle-filled',
                'info': 'information-circle-filled'
            };
            return alertTypeSwitch[this.data.type] ?? 'information-circle-filled';
        },

        getAlertClass() {
            const classes = {
                'alert-content': true,
                'shadow-2': true
            };
            if (this.data.type) {
                classes[`alert-content-${this.data.type}`] = true;
            }
            return classes;
        },

        checkAlertProgress() {
            const startedAt = this.data.startedAt
            const endsAt = startedAt + this.data.expiresIn;

            let diff = endsAt - Date.now();
            if (diff <= 0) diff = 0;

            this.progressPercentage = ((100 * diff) / this.data.expiresIn);
        },

        closeAlert() {
            alertService.clear(this.data.id);
        }
    },
    props: {
        data: Object
    },
    data() {
        return {
            checkAlertProgressIntervalRef: null,
            progressPercentage: null
        }
    },
    mounted() {
        if (this.data?.expiresIn) {
            this.checkAlertProgress();
            this.checkAlertProgressIntervalRef = setInterval(this.checkAlertProgress, 10);
        }
    },
    unmounted() {
        if (this.checkAlertProgressIntervalRef) {
            clearInterval(this.checkAlertProgressIntervalRef);
        }
    }
}