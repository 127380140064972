<template>
  <div class="app-list-container">
    <div class="app-list">
      <div class="new-entity-section">
        <AppButton @click="openCreate()" label="Novo Produto" />
      </div>

      <table>
        <tr>
          <th style="width: 20px; max-width: 20px; min-width: 20px;">ID</th>
          <th>Nome</th>
          <th>SKU</th>
          <th>Tipo</th>
          <th>Valor Unitário</th>
          <th>Unidade</th>
          <th>Preço</th>
          <th>Ativo</th>
          <th>Empresa</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of products" class="table-hover-row">
          <td>{{data.id}}</td>
          <td>{{data.name}}</td>
          <td>{{data.sku}}</td>
          <td>{{data.type}}</td>
          <td>{{data.unit_value}}</td>
          <td>{{data.unit}}</td>
          <td>{{data.price}}</td>
          <td>{{data.is_active ? 'Sim' : 'Não'}}</td>
          <td>{{data.company?.name}}</td>
          <td style="text-align: right;">
            <a @click="openEdit(data)">Editar</a>
            <a @click="remove(data)" class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import { AppButton } from "../../../components";
import {
  productServiceInstance as productService,
  alertServiceInstance as alertService, usageAlertServiceInstance as usageAlertService
} from "../../../services";

export default {
  name: "ListProducts",
  components: {
    AppButton
  },
  methods: {
    openCreate() {
      this.$router.push('/products/create');
    },
    openEdit({ id }) {
      this.$router.push(`/products/create?id=${id}`);
    },
    async remove({ id }) {
      await productService.deleteById(id);
      alertService.success({ desc: 'Produto excluído!' });
      await this.load();
    },
    async load() {
      const data = (await productService.findAll()) ?? [];
      this.products.splice(0, this.products.length);
      this.products.push(...data);
    }
  },
  data: () => ({
    products: []
  }),
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped lang="scss">

</style>