import { chartUtils as ChartUtils } from '../utils/ChartUtils';

export const generateDoughnutData = ({
                                         labels = [ 'Red', 'Orange', 'Yellow', 'Green', 'Blue' ],
                                         cutout = 55
                                    } = {}) => {
    const data = ChartUtils.numbers({ count: labels.length, min: 0, max: 10, decimals: 0 });
    return {
        labels,
        datasets: [
            {
                data,
                cutout,
                label: 'Dataset 1',
                backgroundColor: Object.values(ChartUtils.CHART_COLORS),
                borderWidth: 1
            }
        ],
        __datasetTotal: data.reduce((acc, value) => acc + value, 0)
    };
};

export const generateBarData = () => ({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
    }],
});

const CHART_BAR_DATA = generateBarData();
const CHART_DOUGHNUT_DATA = generateDoughnutData();

export {
    CHART_BAR_DATA,
    CHART_DOUGHNUT_DATA
}