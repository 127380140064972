<template>

  <div class="greetings">
    <div class="greetings-icon">
      <Icon type="user-circle-filled" />
    </div>
    <div class="greetings-user-name">
      Olá, <b>{{userInfo?.first_name}}</b>
    </div>
  </div>

  <div class="chart">
    <div class="chart-section">
      <div class="chart-section-title">Cost management</div>
      <div class="chart-section-sub-title">Última atualização em 01/01/2022 ás 22:00</div>
      <div class="chart-section-balance">
        R$ 2348.39
      </div>
      <div class="chart-container-left">
        <Chart class="doughnut-chart" chartType="doughnut" :chartData="doughnutData_1" chartId="chart-1"/>
        <div class="doughnut-center-label">{{doughnutData_1?.__datasetTotal}}</div>
      </div>
      <div class="chart-container-right">
        <Chart class="doughnut-chart" chartType="doughnut" :chartData="doughnutData_2" chartId="chart-2"/>
      </div>
    </div>

    <div class="chart-section">
      <div class="chart-section-title">Performance</div>
      <div class="chart-section-sub-title">Última atualização em 01/01/2022 ás 22:00</div>
      <hr class="chart-section-hr"/>
      <div class="chart-container-left">
        <Chart class="doughnut-chart" chartType="doughnut" :chartData="doughnutData_3" chartId="chart-3"/>
        <div class="doughnut-center-label">{{doughnutData_3?.__datasetTotal}}</div>
      </div>
      <div class="chart-container-right">
        <Chart class="doughnut-chart" chartType="doughnut" :chartData="doughnutData_4" chartId="chart-4"/>
      </div>
    </div>

    <!--
    <div class="chart-full-section">
      <Chart class="bar-chart" chartType="bar" :chartOptions="barOptions" chartId="bar-chart-1"/>
    </div>
    -->
  </div>

</template>

<script src="./Home.js" />
<style scoped src="./Home.scss" lang="scss" />