<template>
  <div class="app-list-container">
    <div class="app-list">
      <div class="new-entity-section">
        <AppButton @click="openCreate()" label="Novo Contrato" />
      </div>

      <table>
        <tr>
          <th style="width: 20px; max-width: 20px; min-width: 20px;">ID</th>
          <th>Empresa</th>
          <th>Detalhes</th>
          <th>Observações</th>
          <th>Ativo</th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
          <th style="width: 120px; max-width: 120px; min-width: 120px;"></th>
        </tr>
        <tr v-for="data of contracts" class="table-hover-row">
          <td>{{data.id}}</td>
          <td>{{data.company?.name}}</td>
          <td>{{data.details}}</td>
          <td>{{data.observations}}</td>
          <td>{{data.is_active ? 'Sim' : 'Não'}}</td>
          <td style="text-align: center;">
            <a @click="downloadFile(data)">Baixar Arquivo</a>
          </td>
          <td style="text-align: right;">
            <a @click="openEdit(data)">Editar</a>
            <a @click="remove(data)" class="delete-action">Excluir</a>
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import { AppButton } from "../../../components";
import {
  contractServiceInstance as contractService,
  alertServiceInstance as alertService
} from "../../../services";

export default {
  name: "ListContracts",
  components: {
    AppButton
  },
  methods: {
    openCreate() {
      this.$router.push('/contracts/create');
    },
    openEdit({ id }) {
      this.$router.push(`/contracts/create?id=${id}`);
    },
    async remove({ id }) {
      await contractService.deleteById(id);
      alertService.success({ desc: 'Contrato excluído!' });
      await this.load();
    },
    async load() {
      const data = (await contractService.findAll()) ?? [];
      this.contracts.splice(0, this.contracts.length);
      this.contracts.push(...data);
    },
    async downloadFile(contract) {
      await contractService.downloadContractFile(contract.id);
    },
  },
  data: () => ({
    contracts: []
  }),
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped lang="scss">

</style>