<template>
  <div class="breadcrumb">
    <div class="breadcrumb-content">

      <template v-for="item in getBreadcrumbItems()">

        <div :class="getBreadcrumbItemsClass(item)">
          <div v-if="item.icon != null" class="breadcrumb-item-icon">
            <Icon :type="item.icon"/>
          </div>
          <div class="breadcrumb-item-label">
            {{item.label}}
          </div>
          <div v-if="item.showRightIcon" class="breadcrumb-item-icon-right">
            <Icon type="chevron-double-right-filled"/>
          </div>
        </div>

      </template>

    </div>
  </div>
</template>

<script src="./Breadcrumb.js"/>
<style scoped src="./Breadcrumb.scss" lang="scss"/>