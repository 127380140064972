<template>
  <div id="nav" v-bind:class="getMenuClass()">

    <div @click="toggleMenuType" class="menu-icon router-container">
        <span class="router-icon">
          <Icon :type="getMenuIconType()" />
        </span>
    </div>

    <div class="router-container"
         v-for="menu in menuItems"
         @click="selectMenu(menu)">

      <div v-bind:class="getMenuOptionClass(menu)">
        <span class="router-icon">
          <Icon :type="menu.iconType" />
        </span>
        <span v-if="subMenuSelected == null" class="router-label">
          {{menu.label}}
        </span>
      </div>

      <hr v-if="subMenuSelected == null" class="router-hr">
    </div>

    <div v-if="subMenuSelected != null" class="sub-menu-container">
      <div class="sub-menu-option fixed-sub-menu-option"
           @click="closeSubMenu">
        <span class="sub-icon">
           <Icon type="chevron-left" />
        </span>
        <span class="sub-label">
          Voltar
        </span>
      </div>

      <span class="sub-title">
        {{subMenuSelected.parentMenu.subMenuTitle || subMenuSelected.parentMenu.label }}
      </span>

      <template v-for="subMenu in subMenuSelected.options">
        <div :class="getSubMenuOptionClass(subMenu)"
             @click="selectSubMenu(subMenu)">
          <span class="sub-label">
             {{subMenu.label}}
          </span>
        </div>

        <div class="sub-menu-option sub-menu-sub-option"
             v-if="subMenu.subMenus?.length > 0 && subMenu.showOptions"
             v-for="subMenuSub in subMenu.subMenus"
             @click="selectSubMenu(subMenuSub)">
          <span class="sub-label">
             {{subMenuSub.label}}
          </span>
        </div>
      </template>

    </div>
  </div>
</template>

<script src="./Menu.js" />
<style scoped src="./Menu.scss" lang="scss" />