import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { CONTRACTS_MOCK } from "../__mocks/ContractMock";

export class ContractMockService extends BaseMockService {
    constructor() {
        super('contracts', CONTRACTS_MOCK);
    }

    async downloadContractFile(_contractId) {
        return null;
    }
}

export default class ContractService extends BaseService {
    constructor() {
        super('contracts', {
            details: String,
            observations: String,
            company_id: Number,
            is_active: Boolean
        });
    }

    async downloadContractFile(contractId) {
        const response = await super.download(`/v1/contracts/${contractId}/file`, null, null, {
            responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', response.headers['content-disposition']?.split("=")[1]);

        document.body.appendChild(link);
        link.click();
    }

    async findAll() {
        const result = await super.get('/v1/contracts');
        return result.data;
    }

    async findOne(query) {
        throw new Error('ContractService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/contracts/${id}`);
    }

    async create(contract, contractFile) {
        const formData = new FormData();
        this._appendContractFields(contract, contractFile, formData);

        return super.postFormData('/v1/contracts', formData);
    }

    async update(data, companyId) {
        throw new Error('ContractService#update not implemented');
    }

    async updateById(contract, contractFile, id) {
        const formData = new FormData();
        this._appendContractFields(contract, contractFile, formData);

        return super.putFormData(`/v1/contracts/${id}`, formData);
    }

    _appendContractFields(contract, contractFile, formData) {
        if (contractFile) formData.append('file', contractFile);

        ['details', 'observations', 'company_id', 'is_active']
            .filter(key => contract[key] != null && contract[key] !== "")
            .forEach(key => formData.append(key, contract[key]?.toString()));

        return formData;
    }
}