import { AppInput, AppButton, Logo } from "../../components";
import {
    alertServiceInstance as alertService,
    userServiceInstance as userService
} from "../../services";

export default {
    name: "ForgotPassword",
    components: {
        AppInput,
        AppButton,
        Logo
    },
    data() {
        return {
            email: null,
        };
    },
    mounted() {
        if (userService.isLogged()) {
            this.user = null;
            this.$router.push('home');
        }
    },
    methods: {
        async send(event) {
            await this.sendForgotData();
        },

        async sendForgotData() {
            try {
                await userService.forgotPassword(this.email);
                alertService.success({
                    desc: 'Foi encaminhado um e-mail com o token para recuperação de senha!'
                });
            } catch (err) {
                console.error(err?.message);
                alertService.error(
                    this.parseErrorMessage(err?.response?.data)
                );
            }
        },
        async onEnter(source) {
            switch (source) {
                case 'email':
                case 'pass':
                    await this.login();
                    return;
            }
        },
        getLeftPanelClass() {
            return {
                'left-panel': true,
                'login-panel': true,
            }
        },

        getRightPanelClass() {
            return {
                'right-panel': true,
                'login-panel': true,
            }
        },
    }
}
