import { AppInput, AppButton, Logo } from "../../components";
import {
    alertServiceInstance as alertService,
    userServiceInstance as userService
} from "../../services";
import ConfirmMFA from "./confirm-mfa/ConfirmMFA.vue";

export default {
    name: "Login",
    components: {
        AppInput,
        AppButton,
        Logo,
        ConfirmMFA
    },
    data() {
        return {
            confirmMFA: false,
            email: null,
            password: null,
            user: null
        };
    },
    mounted() {
        if (userService.isLogged()) {
            this.confirmMFA = false;
            this.user = null;
            this.$router.push('home');
        }
    },
    methods: {
        async login(event) {
            if (this.validateInput()) {
                /*
                const user = await userService.findOne({
                    usr_email: this.email,
                    usr_password: this.password
                });
                if (user) {
                    this.confirmMFA = true;
                    this.user = user;
                } else {
                    alertService.error({ title: 'Verifique os campos', desc: 'Email e/ou senha inválidos.'});
                    this.user = null;
                }*/
                const { needs_otp_setup, mfa_enabled } = await this.validateUserCredentials();
                if (mfa_enabled && !needs_otp_setup) {
                    this.user = null;
                    this.confirmMFA = true;
                } else {
                    this.user = null;
                    this.confirmMFA = false;
                    await this.sendMFAData();
                }
            }
        },

        validateInput() {
            const title = 'Verifique os campos';

            if (this.email == null || this.email === '') {
                alertService.error({ title, desc: 'Digite seu email.'});
                return false;
            }

            if (this.password == null || this.password === '') {
                alertService.error({ title, desc: 'Digite sua senha.'});
                return false;
            }

            return true;
        },

        async validateUserCredentials() {
            try {
                return await userService.validateCredentials(this.email, this.password, true);
            } catch (err) {
                this.user = null;
                this.confirmMFA = false;
                alertService.error(this.parseErrorMessage(err?.response?.data));
                throw err;
            }
        },

        async sendMFAData(tokenData = null) {
            try {
                this.user = await userService.login(this.email, this.password, tokenData, true);
                userService.setLoggedUser(this.user);
                this.confirmMFA = false;
                if (this.user.needs_otp_setup) {
                    await this.$router.push('/settings/security');
                } else {
                    await this.$router.push('home');
                }
            } catch (err) {
                console.error(err?.message);
                this.user = null;
                alertService.error(
                    this.parseErrorMessage(err?.response?.data, !!tokenData)
                );
            }
        },

        parseErrorMessage(error, fromMFA) {
            switch (error?.errors[0]?.message) {
                case 'EMAIL_OR_PASSWORD_NOT_FOUND':
                    return {
                        title: 'Verifique os campos',
                        desc: fromMFA ? 'Código MFA inválido.' : 'Email e/ou senha inválidos.'
                    };
                default:
                    return { tile: 'Tenta novamente.', desc: 'Falha ao comunicar com servidor' };
            }
        },

        cancelMFA() {
            this.confirmMFA = false;
        },

        getLeftPanelClass() {
            return {
                'left-panel': true,
                'login-panel': true,
                'center-panel': this.confirmMFA
            }
        },

        getRightPanelClass() {
            return {
                'right-panel': true,
                'login-panel': true,
                'blank-panel': this.confirmMFA
            }
        },

        async onEnter(source) {
            switch (source) {
                case 'email':
                case 'pass':
                    await this.login();
                    return;
            }
        },
        created() {
            if (this.isMobile()) {
                this.$router.push('/mobile');
            }
        }
    }
}
