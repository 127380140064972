import { Icon } from "../../index";
import { MenuService } from "../../../services";

export default {
    name: "Breadcrumb",

    methods: {
        getBreadcrumbItems() {
            const currentRoute = this.$route.path;
            const currentMenu = MenuService.AppItems
                .find(item => {

                    // TODO: Improve/refactor recursive code/structure
                    if (item.hasSubMenu) {
                        return item.subMenus.some(subMenu => {
                            if (subMenu.subMenus?.length) {
                                return subMenu.subMenus.some(sm => sm.routerLink === currentRoute);
                            } else {
                                return subMenu.routerLink === currentRoute;
                            }
                        });
                    } else {
                        return item.routerLink === currentRoute;
                    }
                });

            const result = []

            // TODO: Improve/refactor recursive code/structure
            if (currentMenu.hasSubMenu) {
                const currentSubMenu = currentMenu.subMenus.find(subMenu => {
                    return subMenu.routerLink === currentRoute || subMenu.subMenus?.some(sm => sm.routerLink === currentRoute)
                });

                if (currentSubMenu.routerLink !== currentRoute) {
                    const currentSubMenuSub = currentSubMenu.subMenus?.find(sm => sm.routerLink === currentRoute);
                    result.push(
                        { label: currentMenu.subMenuTitle || currentMenu.label, icon: currentMenu.iconType, isActive: false, showRightIcon: true },
                        { label: currentSubMenu.subMenuTitle || currentSubMenu.label, icon: currentSubMenu.iconType, isActive: false, showRightIcon: true },
                        { label: currentSubMenuSub.subMenuTitle || currentSubMenuSub.label, icon: currentSubMenuSub.iconType, isActive: true, showRightIcon: false },
                    );
                } else {
                    result.push(
                        { label: currentMenu.subMenuTitle || currentMenu.label, icon: currentMenu.iconType, isActive: false, showRightIcon: true },
                        { label: currentSubMenu.subMenuTitle || currentSubMenu.label, icon: currentSubMenu.iconType, isActive: true, showRightIcon: false }
                    );
                }
            } else {
                result.push({
                    label: currentMenu.subMenuTitle || currentMenu.label,
                    icon: currentMenu.iconType,
                    isActive: true,
                    showRightIcon: false
                });
            }
            return result;
        },

        getBreadcrumbItemsClass({ isActive }) {
            return {
                'breadcrumb-item': true,
                'breadcrumb-item-active': isActive
            }
        }
    },

    components: {
        Icon
    },
}