import { AppButton, Slide } from "../../../components";
import {
    contractServiceInstance as contractService,
    companyServiceInstance as companyService,
    alertServiceInstance as alertService
} from "../../../services";

export default {
    name: "RegisterContract",
    components: { AppButton, Slide },
    methods: {
        async saveContract() {
            let alertDesc = '';
            if (this.isEdit) {
                await contractService.updateById(this.contract, this.contractFile, this.contract.id);
                alertDesc = 'Contrato atualizado!';
            } else {
                await contractService.create(this.contract, this.contractFile);
                alertDesc = 'Contrato criado!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/contracts/list');
        },

        handleFileUpload(event) {
            this.contractFile = event.target.files[0];
        }
    },
    data() {
        return {
            contract: {},
            companies: [],
            contractFile: null,
            isEdit: false
        }
    },
    async mounted() {
        this.companies = await companyService.findAll();
        const contractId = this.$route.query.id;
        if (contractId) {
            this.contract = (await contractService.findOneById(contractId)) ?? {};
            this.isEdit = true;
        }
    }
}