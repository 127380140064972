import { AppButton, Filter } from "../../../../components";

const SAMPLE_EMAIL_CODE = `Prezado(a) {userName}

Esta mensagem é um alerta do serviço {productName} que atingiu {thresholdPercentage} de utilização em {alertDate}.

Atenciosamente,
Operação {companyName}

**Atenção, este é um e-mail automático, por favor não responda.`;

export default {
    name: "RegisterNotificationTemplate",
    components: {
        AppButton,
        Filter,
    },
    methods: {
        voltar() {
            this.$router.push('/settings/notification-templates/list');
        }
    },
    data: () => ({
        templatesList: [
            { type: 'Email', templateKey: 'Alerta', createdAt: '01/01/2022 23:49', updatedAt: '12/01/2022 13:27' },
            { type: 'Email', templateKey: 'Recuperação de Senha', createdAt: '01/01/2022 23:49', updatedAt: '12/01/2022 13:27' },
        ],
        templateCode: SAMPLE_EMAIL_CODE
    })
}