import {AppButton} from "@/components";
import PDFViewer from 'pdf-viewer-vue'

export default {
    name: "DetailingContract",
    components: { AppButton, PDFViewer },
    methods: {

    },
    data() {
        return {
            contract: {
                number: 1234,
                validity: "18/06/2020 a 18/06/2025",
                indexReajust: "INPC - Índice Nacional de Preços ao Consumidor",
                signatureDate: "13/02/2020",
                birthdayDate: "Fevereiro de cada ano"
            },
            pdf: {
                url: "https://blush-arline-83.tiiny.site/"
            }
        }
    }
}