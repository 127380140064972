import { createRouter, createWebHashHistory } from 'vue-router'
import {
  Login,
  Home,
  BillingReport,

  RegisterCompany,
  RegisterGroup,
  RegisterProject,
  RegisterProduct,
  RegisterUsageAlert,
  RegisterProductFactor,
  RegisterContract,
  RegisterProfile,
  RegisterUser,
  RegisterNotificationTemplate,

  SecuritySettings,

  ListCompanies,
  ListProducts,
  ListProjects,
  ListContracts,
  DetailingContract,
  ListUsageAlerts,
  ListGroups,
  ListProfiles,
  ListUsers,
  ListNotificationTemplates,

  CloudReport,
  OnPremiseReport,
  Billing,
  ForgotPassword,
  MobileBlock,
  ResetPassword
} from '../views';
import {
  userServiceInstance as userService,
  alertServiceInstance as alertService,
  mobileServiceInstance as mobileService,
} from '../services';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:email',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/billing-report',
    name: 'BillingReport',
    component: BillingReport
  },
  {
    path: '/mobile',
    name: 'MobileBlock',
    component: MobileBlock
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing
  },

  { path: '/companies/create', name: 'RegisterCompany', component: RegisterCompany },
  { path: '/companies/list', name: 'ListCompanies', component: ListCompanies },

  { path: '/products/create', name: 'RegisterProduct', component: RegisterProduct },
  { path: '/products/list', name: 'ListProducts', component: ListProducts },

  { path: '/projects/create', name: 'RegisterProject', component: RegisterProject },
  { path: '/projects/list', name: 'ListProjects', component: ListProjects },

  { path: '/contracts/create', name: 'RegisterContract', component: RegisterContract },
  { path: '/contracts/list', name: 'ListContracts', component: ListContracts },

  { path: '/contract/detailing', name: 'DetailingContract', component: DetailingContract },

  { path: '/usage-alerts/create', name: 'RegisterUsageAlert', component: RegisterUsageAlert },
  { path: '/usage-alerts/list', name: 'ListUsageAlerts', component: ListUsageAlerts },

  { path: '/groups/create', name: 'RegisterGroup', component: RegisterGroup },
  { path: '/groups/list', name: 'ListGroups', component: ListGroups },

  { path: '/profiles/create', name: 'RegisterProfile', component: RegisterProfile },
  { path: '/profiles/list', name: 'ListProfiles', component: ListProfiles },

  { path: '/users/create', name: 'RegisterUser', component: RegisterUser },
  { path: '/users/list', name: 'ListUsers', component: ListUsers },

  {
    path: '/product-factors/create',
    name: 'RegisterProductFactor',
    component: RegisterProductFactor
  },
  {
    path: '/settings/security',
    name: 'SecuritySettings',
    component: SecuritySettings
  },
  {
    path: '/settings/notification-templates/list',
    name: 'ListNotificationTemplates',
    component: ListNotificationTemplates
  },
  {
    path: '/settings/notification-templates/create',
    name: 'RegisterNotificationTemplate',
    component: RegisterNotificationTemplate
  },
  {
    path: '/costs/cloud-report',
    name: 'CloudReport',
    component: CloudReport
  },
  {
    path: '/costs/on-premise-report',
    name: 'OnPremiseReport',
    component: OnPremiseReport
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to?.name !== 'Login' && to?.name !== 'ForgotPassword' && to?.name !== 'MobileBlock' && to?.name !== 'ResetPassword') {
    if (!userService?.isLogged()) {
      alertService.warning({ desc: 'Informe seus dados.' });
      return '/';
    } else if (userService?.isTokenExpired()) {
      alertService.warning({ desc: 'Sessão expirada.' });
      return '/';
    } else if (to?.name !== 'SecuritySettings' && userService?.loggedUser?.needs_otp_setup === true) {
      alertService.warning({ desc: 'Complete o processo de MFA!' });
      return '/settings/security';
    }
  }
  if(mobileService?.isMobile() && to?.name !== 'MobileBlock'){
    return next({ name: 'MobileBlock' });
  }
  next();
  return true;
})

export default router
