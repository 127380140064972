<template>
  <template v-if="canShowMenu()">
    <Navbar :class="getPrefixMenuClass('navbar')"/>
    <Menu @expand="onMenuExpand" />
    <Breadcrumb :class="getPrefixMenuClass('breadcrumb')"/>
  </template>
  <div class="app-alert-container">
    <Alert v-for="alert in alerts"
           :data="alert"/>
  </div>
  <div :class="getAppRouterContentClass()">
    <router-view/>
  </div>
</template>

<script src="./App.js" />
<style src="./App.scss" lang="scss"/>