import { Icon, Filter, AppButton, Logo } from "../../components";
import BILLING from '../../__mocks/BillingMock';
import BILLING_INFORMATION from "../../__mocks/BillingInformationMock";

const BILLING_COLUMNS = [
    { active: true,  key: 'LINE', label: 'Linha'},
    { active: true, key: 'SERVICE_ORDER', label: 'Ordem de Serviço' },
    { active: true, key: 'ALOCATION', label: 'Alocação' },
    { active: false, key: 'PRODUCT', label: 'Produto' },
    { active: false, key: 'DESCRIPTION', label: 'Descrição' },
    { active: true,  key: 'TYPE', label: 'Tipo' },
    { active: false, key: 'QUANTITY', label: 'Quantidade' },
    { active: false, key: 'UNITY_PRICE', label: 'Preço Unitário' },
    { active: false, key: 'RECCURENCY', label: 'Recorrência' },
    { active: false, key: 'NOT-RECCURENCY', label: 'Não Recorrência' },
    { active: true, key: 'SUBTOTAL-PRICE', label: 'Subtotal em R$' },
];

export default {
    name: "Billing",

    components: {
        Icon,
        Filter,
        AppButton,
        Logo
    },

    methods: {
        isColumnActive(columnKey) {
            return this.billingColumns.get(columnKey).active;
        },

        getColumns() {
            return Array.from(this.billingColumns.values());
        },

        getActiveColumnsLength() {
            return Array.from(this.billingColumns.values())
                .filter(column => column.active)
                .length;
        },

        getColumnsLength() {
            return Array.from(this.billingColumns.values())
                .length;
        },

        toggleColumn(columnKey) {
            const column = this.billingColumns.get(columnKey);
            if (column) {
                column.active = !column.active;
            }
        },

        toggleColumnOptions() {
            this.showColumnOptions = !this.showColumnOptions;
        },

        toggleMoreFilters() {
            this.showMoreFilters = !this.showMoreFilters;
        }
    },

    data() {
        return {
            billingColumns: new Map(BILLING_COLUMNS.map(column => [column.key, column])),
            billing: BILLING,
            billingInfo: BILLING_INFORMATION,
            showColumnOptions: false,
            showMoreFilters: false
        }
    }
}
