import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Icon, Navbar, Filter, Logo } from './components';

const app = createApp(App);

app.component('Icon', Icon);
app.component('Navbar', Navbar);
app.component('Filter', Filter);
app.component('Logo', Logo);

app.use(router);
app.mount('#app');
