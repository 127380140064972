<template>
  <div id="register-user">

    <div class="form-input-container">

      <div style="display: inline-block; width: calc(35% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Nome:</span>
        <input v-model="user.first_name" type="text" placeholder="Digite o nome"/>
      </div>

      <div style="display: inline-block; width: calc(35% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Sobrenome:</span>
        <input v-model="user.last_name" type="text" placeholder="Digite o sobrenome"/>
      </div>

      <div style="display: inline-block; width: calc(30% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Tratamento:</span>
        <input v-model="user.treatment" type="text" placeholder="Digite o tratamento"/>
      </div>

      <div class="form-input-section">
        <span>Email:</span>
        <input autocomplete="off" v-model="user.email" type="email" placeholder="Digite o email"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Senha:</span>
        <input id="pass-register" autocomplete="off" v-model="user.password"  type="password" placeholder="Digite a senha"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Confirme a senha:</span>
        <input id="pass-confirm" autocomplete="off" v-model="user.password_confirmation" type="password" placeholder="Confirme a senha"/>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Perfil:</span>
        <select v-model="user.profile_id" >
          <option v-for="profile in profiles" :value="profile.id" selected>{{profile.name}}</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(50% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Grupo:</span>
        <select v-model="user.group_id" >
          <option v-for="group in groups" :value="group.id" selected>{{group.name}}</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(60% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Empresa:</span>
        <select v-model="user.company_id" >
          <option v-for="company in companies" :value="company.id" selected>{{company.name}}</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(15% - 10px); margin-left: 30px;" class="form-input-section">
        <span>MFA:</span>
        <label class="switch">
          <input v-model="user.enable_mfa_setup" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>

      <div style="display: inline-block; width: calc(15% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Ativo:</span>
        <label class="switch">
          <input v-model="user.is_active" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>

    </div>

    <div class="form-input-actions">
      <AppButton @click="saveUser()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterUser.js" />
<style scoped src="./RegisterUser.scss" lang="scss" />