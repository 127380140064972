import { AppButton, Icon } from "../../../components";
import {
    profileServiceInstance as profileService,
    alertServiceInstance as alertService,
    MenuService
} from "../../../services";
import { toRaw } from 'vue';

const copyObject = (obj = {}) => JSON.parse(JSON.stringify(obj));

export default {
    name: "RegisterProfile",
    components: { AppButton, Icon },
    methods: {
        async saveProfile() {
            this.profile.permissions = this.getSelectedMenus();
            let alertDesc = '';
            if (this.isEdit) {
                await profileService.updateById(this.profile, this.profile.id);
                alertDesc = 'Perfil de Acesso atualizado!';
            } else {
                await profileService.create(this.profile);
                alertDesc = 'Perfil de Acesso criado!';
            }
            alertService.success({ desc: alertDesc });
            await this.$router.push('/profiles/list');
        },

        getSelectedMenus() {
            return toRaw(this.menuItems)
                .reduce((acc, menu) => {
                    if (menu.selected) acc.push(menu.id);

                    menu.subMenus?.forEach(sm => {
                        if (sm.selected) acc.push(sm.id);

                        sm.subMenus?.forEach(smSub => {
                            if (smSub.selected) acc.push(smSub.id);
                        });
                    });

                    return acc;
                }, []);
        },

        parseSelectedMenus() {
            if (this.profile?.permissions?.length && this.menuItems?.length) {
                const permissions = this.profile.permissions;
                this.menuItems.forEach(menu => {
                    menu.selected = permissions.includes(menu.id);
                    menu.subMenus?.forEach(sm => {
                        sm.selected = permissions.includes(sm.id);
                        sm.subMenus?.forEach(smSub => smSub.selected = permissions.includes(smSub.id));
                    });
                });
            }
        },

        getMenuOptionClass(menu) {
            return {
                'menu-option': true,
                'menu-option-selected': menu.selected
            };
        },

        getSubMenuOptionClass(subMenu) {
            return {
                'menu-option': true,
                'sub-menu-option': true,
                'menu-option-selected': subMenu.selected
            };
        },

        getSubMenuSubOptionClass(subMenuSub) {
            return {
                'menu-option': true,
                'sub-menu-sub-option': true,
                'menu-option-selected': subMenuSub.selected
            };
        },

        selectMenuOption(menu) {
            menu.selected = !menu.selected;
            menu.subMenus?.forEach(sm => this.selectSubMenuOption(sm, menu, menu.selected))
        },

        selectSubMenuOption(subMenu, parentMenu, value = !subMenu.selected) {
            subMenu.selected = value;
            subMenu.subMenus?.forEach(sm => this.selectSubMenuSubOption(sm, [subMenu, parentMenu], subMenu.selected));

            if (subMenu.selected) {
                parentMenu.selected = true;
            }
        },

        selectSubMenuSubOption(subMenuSub, parentMenus, value = !subMenuSub.selected) {
            subMenuSub.selected = value;

            if (subMenuSub.selected) {
                parentMenus?.forEach(pm => pm.selected = true);
            }
        }
    },
    data() {
        return {
            profile: {},
            isEdit: false,
            menuItems: copyObject(MenuService.MenuItems)
        }
    },
    async mounted() {
        const profileId = this.$route.query.id;
        if (profileId) {
            this.profile = (await profileService.findOneById(profileId)) ?? {};
            this.isEdit = true;
        }

        this.parseSelectedMenus();
    }
}