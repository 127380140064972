import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { COMPANIES_MOCK } from "../__mocks/CompanyMock";

export class CompanyMockService extends BaseMockService {
    constructor() {
        super('companies', COMPANIES_MOCK);
    }
}

export default class CompanyService extends BaseService {
    constructor() {
        super('companies', {
            name: String,
            address: String,
            zipcode: String,
            city: String,
            state: String,
            cellphone: String,
            email: String,
            financial_email: String,
            technical_email: String,
            commercial_email: String,
            logo_url: String,
            company_url: String,
            observations: String,
            is_active: Boolean
        });
    }

    async findAll() {
        const result = await super.get('/v1/companies');
        return result.data;
    }

    async findOne(query) {
        throw new Error('CompanyService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/companies/${id}`);
    }

    async create(data) {
        return super.post('/v1/companies', data);
    }

    async update(data, companyId) {
        throw new Error('CompanyService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/companies/${id}`, data);
    }
}