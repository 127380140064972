<template>
  <div class="user-options-container shadow-1">

    <div class="user-info">
      <span class="user-name">{{userInfo?.first_name}}</span>
      <span class="user-email">{{userInfo?.email}}</span>
    </div>

    <div class="user-menu-option">
        <span class="user-menu-icon">
           <Icon type="user-filled" />
        </span>
        <span class="user-menu-label">
           Meus Dados
        </span>
    </div>

    <div @click="openSecurityOption()" class="user-menu-option">
        <span class="user-menu-icon">
           <Icon type="cog-filled" />
        </span>
        <span class="user-menu-label">
           Segurança
        </span>
    </div>

    <span @click="logout()" class="logout-option">
      Sair
    </span>

  </div>
</template>

<script src="./UserOptions.js" />
<style scoped src="./UserOptions.scss" lang="scss" />