import { Icon } from "../../index";

export default {
    name: 'Filter',
    components: {
        Icon
    },
    props: {
        label: String,
        data: String,
        icon: String
    }
}