<template>
  <div class="login-container logo-bg">
    <div :class="getLeftPanelClass()">

      <div class="input-container">
        <div class="login-logo">
          <!--
          <img alt="" src="../../assets/logo-transparent.png"/>
          -->
          <Logo class="login-logo-content"/>
        </div>

        <!--
        <h2>Faça login</h2>
        -->

        <AppInput @keyup.enter="onEnter('email')" style="margin-top: 60px" v-model="email" class="login-input" label="Email" type="email" placeholder="" />
        <AppButton @click="send" label="Enviar" />
        <a class="forgot-password" href="/">Realizar Login</a>
      </div>

    </div>
    <div :class="getRightPanelClass()">

    </div>
  </div>
</template>

<script src="./ForgotPassword.js" />
<style scoped src="../login/Login.scss" lang="scss" />
