<template>
  <div id="register-usage-alert">

    <div class="form-input-container">

      <div style="display: inline-block; width: calc(70% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Produto:</span>
        <select v-model="usageAlert.product_id" >
          <option v-for="product in products" :value="product.id" selected>{{product.name}}</option>
        </select>
      </div>

      <div class="form-input-section">
        <span>Porcentagem de Uso:</span>
        <input v-model="usageAlert.percentage_threshold" type="number" placeholder="Digite a Porcentagem de Uso"/>
      </div>

      <div class="form-input-section">
        <span>Valor:</span>
        <input v-model="usageAlert.value_threshold" type="number" placeholder="Digite o Valor"/>
      </div>

      <div style="display: inline-block; width: calc(70% - 10px); margin-right: 10px;" class="form-input-section">
        <span>Projeto:</span>
        <select v-model="usageAlert.project_id" >
          <option v-for="project in projects" :value="project.id" selected>{{project.name}}</option>
        </select>
      </div>

      <div style="display: inline-block; width: calc(30% - 10px); margin-left: 10px;" class="form-input-section">
        <span>Ativo:</span>
        <label class="switch">
          <input v-model="usageAlert.is_active" type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="form-input-actions">
      <AppButton @click="saveUsageAlert()" label="Salvar" />
    </div>

  </div>
</template>

<script src="./RegisterUsageAlert.js" />
<style scoped src="./RegisterUsageAlert.scss" lang="scss" />