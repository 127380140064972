import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { GROUPS_MOCK } from "../__mocks/GroupMock";

export class GroupsMockService extends BaseMockService {
    constructor() {
        super('groups', GROUPS_MOCK);
    }

    async downloadContractFile(_contractId) {
        return null;
    }
}

export default class GroupsService extends BaseService {
    constructor() {
        super('groups', {
            name: String,
            company_id: Number,
            is_active: Boolean
        });
    }

    async findAll() {
        const result = await super.get('/v1/groups');
        return result.data;
    }

    async findOne(query) {
        throw new Error('GroupsService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/groups/${id}`);
    }

    async create(data) {
        return super.post('/v1/groups', data);
    }

    async update(data, companyId) {
        throw new Error('GroupsService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/groups/${id}`, data);
    }
}