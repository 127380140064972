import BaseService from './base-service';
import BaseMockService from "./base-mock-service";
import { PROJECT_MOCK } from "../__mocks/ProjectMock";

export class ProjectMockService extends BaseMockService {
    constructor() {
        super('projects', PROJECT_MOCK);
    }
}

export default class ProjectService extends BaseService {
    constructor() {
        super('projects', {
            name: String,
            limit: String,
            cost_center: String,
            observations: String,
            company_id: Number
        });
    }

    async findAll() {
        const result = await super.get('/v1/projects');
        return result.data;
    }

    async findOne(query) {
        throw new Error('ProjectService#findOne not implemented');
    }

    async findOneById(id) {
        return super.get(`/v1/projects/${id}`);
    }

    async create(data) {
        return super.post('/v1/projects', data);
    }

    async update(data, companyId) {
        throw new Error('ProjectService#update not implemented');
    }

    async updateById(data, id) {
        return super.put(`/v1/projects/${id}`, data);
    }
}