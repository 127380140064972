<template>
  <div class="detailing-container">
    <div class="table-filter-container">
      <div class="table-filters">

        <table class="filter-table-section">
          <tr>
            <td><label class="label-first">Contrato Número: <label class="label-second">{{contract.number}}</label></label></td><br>
          </tr>
          <tr>
            <td><label class="label-first">Vigência: <label class="label-second">{{ contract.validity }}</label></label></td><br>
          </tr>
          <tr>
            <td><label class="label-first">Indice para reajuste: <label class="label-second">{{ contract.indexReajust }}</label></label></td><br>
          </tr>
          <tr>
            <td><label class="label-first">Data de Assinatura: <label class="label-second">{{ contract.signatureDate }}</label></label></td><br>
          </tr>
          <tr>
            <td><label class="label-first">Data de Aniversário: <label class="label-second">{{ contract.birthdayDate }}</label></label></td><br>
          </tr>
        </table>

        <PDFViewer
            :source="pdf.url"
            style="display:flex; height: 70vh; width: 70vw"
        />

      </div>
    </div>
  </div>
</template>


<script src="./DetailingContract.js" />

<style scoped src="./DetailingContract.scss" lang="scss" />