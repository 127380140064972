import colorLib from '@kurkle/color';
import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';

export class ChartUtils {

    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
    _seed = Date.now();

    valueOrDefault(value, defaultValue) {
        return value != null ? value : defaultValue;
    }
    
    srand(seed) {
        this._seed = seed;
    }

    rand(min, max) {
        min = this.valueOrDefault(min, 0);
        max = this.valueOrDefault(max, 0);
        this._seed = (this._seed * 9301 + 49297) % 233280;
        return min + (this._seed / 233280) * (max - min);
    }

    numbers(config) {
        var cfg = config || {};
        var min = this.valueOrDefault(cfg.min, 0);
        var max = this.valueOrDefault(cfg.max, 100);
        var from = this.valueOrDefault(cfg.from, []);
        var count = this.valueOrDefault(cfg.count, 8);
        var decimals = this.valueOrDefault(cfg.decimals, 8);
        var continuity = this.valueOrDefault(cfg.continuity, 1);
        var dfactor = Math.pow(10, decimals) || 0;
        var data = [];
        var i, value;

        for (i = 0; i < count; ++i) {
            value = (from[i] || 0) + this.rand(min, max);
            if (this.rand() <= continuity) {
                data.push(Math.round(dfactor * value) / dfactor);
            } else {
                data.push(null);
            }
        }

        return data;
    }

    points(config) {
        const xs = this.numbers(config);
        const ys = this.numbers(config);
        return xs.map((x, i) => ({x, y: ys[i]}));
    }

    bubbles(config) {
        return this.points(config).map(pt => {
            pt.r = this.rand(config.rmin, config.rmax);
            return pt;
        });
    }

    labels(config) {
        var cfg = config || {};
        var min = cfg.min || 0;
        var max = cfg.max || 100;
        var count = cfg.count || 8;
        var step = (max - min) / count;
        var decimals = cfg.decimals || 8;
        var dfactor = Math.pow(10, decimals) || 0;
        var prefix = cfg.prefix || '';
        var values = [];
        var i;

        for (i = min; i < max; i += step) {
            values.push(prefix + Math.round(dfactor * i) / dfactor);
        }

        return values;
    }

    MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    months(config) {
        var cfg = config || {};
        var count = cfg.count || 12;
        var section = cfg.section;
        var values = [];
        var i, value;

        for (i = 0; i < count; ++i) {
            value = this.MONTHS[Math.ceil(i) % 12];
            values.push(value.substring(0, section));
        }

        return values;
    }

    COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];

    color(index) {
        return this.COLORS[index % this.COLORS.length];
    }

    transparentize(value, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return colorLib(value).alpha(alpha).rgbString();
    }

    get CHART_COLORS() {
        return {
            red: 'rgb(255, 99, 132)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
            green: 'rgb(75, 192, 192)',
            blue: 'rgb(54, 162, 235)',
            purple: 'rgb(153, 102, 255)',
            grey: 'rgb(201, 203, 207)'
        };
    }

    NAMED_COLORS = [
        this.CHART_COLORS.red,
        this.CHART_COLORS.orange,
        this.CHART_COLORS.yellow,
        this.CHART_COLORS.green,
        this.CHART_COLORS.blue,
        this.CHART_COLORS.purple,
        this.CHART_COLORS.grey,
    ];

    namedColor(index) {
        return this.NAMED_COLORS[index % this.NAMED_COLORS.length];
    }

    newDate(days) {
        return DateTime.now().plus({days}).toJSDate();
    }

    newDateString(days) {
        return DateTime.now().plus({days}).toISO();
    }

    parseISODate(str) {
        return DateTime.fromISO(str);
    }
}

export const chartUtils = new ChartUtils();